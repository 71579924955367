import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  Chip,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "react-bootstrap"; // Import Bootstrap Table
import ErrorPage from "../../../../components/errorPage/ErrorPage";
import { useAuth } from "../../../../context/AuthContext";
import NoRecordMessage from "../../../../components/noRecordMessage/NoRecordMessage";
import TableSkeletonLoading from "../../../../components/tableSkeletonLoading/TableSkeletonLoading";
import { useNavigate } from "react-router-dom";
import useDelete from "../../../../api/useDelete";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";
import PaymentLoan from "./PaymentLoan";

import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

const LoanList = () => {
  const [value, setValue] = useState("");
  const [openDialog, setOpenDialog] = useState(null);
  const { t } = useTranslation();
  const [filterDate, setFilterDate] = useState(""); // State for date filtering
  const [paidFilter, setPaidFilter] = useState(""); // State for paid/unpaid filtering
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const [keyword, setKeyword] = useState("");

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["LoanList"],
    `loan/?search=${keyword}&loan_date=${filterDate}&is_paid=${paidFilter}`, // Updated query
    token
  );

  const { handleDelete, DeleteMessageComponent, ConfirmDialog } = useDelete(
    `loan`,
    token
  );

  useEffect(() => {
    refetch();
  }, [filterDate, refetch, keyword, paidFilter]); // Added filterDate to dependencies

  if (isLoading) {
    return <TableSkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage />;
  }
  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid
        item
        xs={12}
        md={11.5}
        padding={"15px"}
        bgcolor={"white"}
        borderRadius={"5px"}
      >
        <Typography variant="h6" fontWeight={"bold"} gutterBottom>
          {t("loan.loanList")}
        </Typography>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item xs={12} md={5} mb={1}>
            <TextField
              fullWidth
              type="search"
              size="small"
              placeholder={t("purchase.search")}
              name="keyword"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              sx={{
                bgcolor: "#e6e6eb",
                borderRadius: "4px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} textAlign={"end"}>
            <TextField
              type="date"
              size="small"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
              sx={{ width: 150, marginLeft: 2 }}
            />
            <FormControl size="small" sx={{ width: 150, marginLeft: 2 }}>
              <InputLabel>{t("loan.paidstatus")}</InputLabel>
              <Select
                value={paidFilter}
                label={t("loan.paidstatus")}
                onChange={(e) => setPaidFilter(e.target.value)}
              >
                <MenuItem value="All">{t("sales.all")}</MenuItem>
                <MenuItem value="true">{t("sales.paid")}</MenuItem>
                <MenuItem value="false">{t("sales.unpaid")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mb={1}>
          <DeleteMessageComponent />
        </Grid>

        {data?.length === 0 ? (
          <NoRecordMessage entityName={t("loan.loan")} />
        ) : (
          <div className="table-responsive">
            <Table bordered hover className="table table-striped table-sm">
              <thead className="table-dark">
                <tr>
                  <th>{t("purchase.num")}</th>
                  <th>{t("loan.customer")}</th>
                  <th>{t("loan.isPaid")}</th>
                  <th>{t("loan.loanAmount")}</th>
                  <th>{t("loan.loanDate")}</th>
                  <th>{t("loan.repayment")}</th>
                  <th>{t("loan.sale")}</th>
                  <th>Return</th>
                  <th>{t("purchase.action")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, i) => (
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>
                      {item.customer_display?.firstname}{" "}
                      {item.customer_display?.lastname}
                    </td>
                    <td>
                      <Chip
                        label={item.is_paid ? "Paid" : "Unpaid"}
                        color={item.is_paid ? "success" : "error"}
                        variant="outlined"
                        sx={{ width: { xs: "20vw", sm: "15vw", md: "7vw" } }}
                      />
                    </td>
                    <td>
                      {item.loan_amount} {item.currency === "AFG" && "؋"}{" "}
                      {item.currency === "USD" && "$"}{" "}
                      {item.currency === "PKR" && "PKR"}{" "}
                    </td>
                    <td>{item.loan_date}</td>
                    <td>
                      {item.repayment} {item.currency === "AFG" && "؋"}{" "}
                      {item.currency === "USD" && "$"}{" "}
                      {item.currency === "PKR" && "PKR"}{" "}
                    </td>
                    <td>{item.display_bill}</td>
                    <td>
                      <KeyboardReturnIcon
                        sx={{ color: "blue", cursor: "pointer" }}
                        fontSize="large"
                        onClick={() => {
                          setValue(item);
                          handleOpenDialog();
                        }}
                      />
                    </td>
                    <td>
                      <DeleteIcon
                        sx={{ color: "#f09690", cursor: "pointer" }}
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <ConfirmDialog />
      </Grid>

      <Dialog open={openDialog} onClose={handleOpenDialog}>
        <DialogTitle>Payment Past Loan</DialogTitle>
        <PaymentLoan data={value} onClose={handleOpenDialog} />
      </Dialog>
    </Grid>
  );
};

export default LoanList;
