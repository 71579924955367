import React, { useEffect, useState } from "react";
import useFetchObjects from "../../../../api/useFetchObjects";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ErrorPage from "../../../../components/errorPage/ErrorPage";
import { useAuth } from "../../../../context/AuthContext";
import NoRecordMessage from "../../../../components/noRecordMessage/NoRecordMessage";
import TableSkeletonLoading from "../../../../components/tableSkeletonLoading/TableSkeletonLoading";
import useDelete from "../../../../api/useDelete";
import { useNavigate } from "react-router-dom";

import { Table } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

const InventoryList = () => {
  const { t } = useTranslation();
  const [filterProduct, setFilterProduct] = useState(null); // State for product filtering using Autocomplete
  const [keyword, setKeyword] = useState(""); // Search keyword state
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  // Fetching inventory data and products for filtering
  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["InventoryList"],
    `inventory/?search=${keyword}&product=${filterProduct?.id || ""}`,
    token
  );
  const {
    data: productData,
    isLoading: isLoadingProduct,
    isError: isErrorProduct,
  } = useFetchObjects(["Product"], `products/`, token);

  const { handleDelete, ConfirmDialog } = useDelete("inventory", token);

  useEffect(() => {
    refetch(); // Refetch the data when the filter or keyword changes
  }, [filterProduct, refetch, keyword]);

  // If data is loading, show the loading skeleton
  if (isLoading || isLoadingProduct) {
    return <TableSkeletonLoading />;
  }

  // If there is an error, show the error page
  if (isError || isErrorProduct) {
    return <ErrorPage />;
  }

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid
        item
        xs={12}
        md={11.9}
        padding={"15px"}
        bgcolor={"white"}
        borderRadius={"5px"}
      >
        <Typography variant="h6" fontWeight={"bold"} gutterBottom>
          {t("inventory.inventoryList")}
        </Typography>

        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={1}
          spacing={2}
        >
          {/* Search Field */}
          <Grid item xs={12} sm={8} md={5}>
            <TextField
              fullWidth
              type="search"
              size="small"
              placeholder={t("inventory.search")}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              sx={{
                bgcolor: "#e6e6eb",
                borderRadius: "4px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* Product Filter using Autocomplete */}
          <Grid
            item
            xs={12}
            sm={4}
            md={6}
            display={"flex"}
            justifyContent={"end"}
        
          >
            <Autocomplete
              options={productData || []}
              getOptionLabel={(option) => option.name || ""}
              onChange={(event, newValue) => {
                setFilterProduct(newValue);
              }}
              value={filterProduct}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder={t("inventory.filterBy")}
                />
              )}
              sx={{ width: 180 }}
            />
          </Grid>
        </Grid>

        {/* Data Table */}
        {data?.length === 0 ? (
          <NoRecordMessage entityName={t("inventory.inventory")} />
        ) : (
          <div className="table-responsive">
            <Table bordered hover className="table table-striped table-sm">
              <thead className="table-dark">
                <tr style={{fontSize:"14px"}}>
                  <th>{t("purchase.num")}</th>
                  <th>{t("inventory.itemName")}</th>
                  <th>{t("purchase.quantity")}</th>
                  <th>{t("purchase.unitPrice")}</th>
                  {/* 
                  <th>{t("purchase.retailSalePrice")}</th>
                  <th>{t("purchase.wholesalePrice")}</th> */}

                  <th>{t("purchase.wholesalePrice")}{t("inventory.afg")}</th>
                  <th>{t("purchase.wholesalePrice")}{t("inventory.usd")}</th>
                  <th>{t("purchase.wholesalePrice")}{t("inventory.pkr")}</th>

                  <th>{t("purchase.retailSalePrice")}{t("inventory.afg")}</th>
                  <th>{t("purchase.retailSalePrice")}{t("inventory.usd")}</th>
                  <th>{t("purchase.retailSalePrice")}{t("inventory.pkr")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, i) => (
                  <tr key={item.id}>
                    <td>{i + 1}</td>
                    <td>{item.product_display}</td>
                    <td>{item.item_quantity}</td>
                    <td>
                      {item.unit_price} {item.currency}{" "}
                    </td>
                    <td>{item.whole_sale_price_in_afg}</td>
                    <td>{item.whole_sale_price_in_usd}</td>
                    <td>{item.whole_sale_price_in_pkr}</td>
                    <td>{item.retail_sale_price_in_afg}</td>
                    <td>{item.retail_sale_price_in_usd}</td>
                    <td>{item.retail_sale_price_in_pkr}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <ConfirmDialog />
      </Grid>
    </Grid>
  );
};

export default InventoryList;
