import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Grid } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context/AuthContext";
import { useTranslation } from "react-i18next";

const AddCustomer = ({ md = 4 }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    father_name: "",
    phone: "",
    email: "",
    address: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const { handleAdd, resetForm, Buttons, setResetForm, FormMessageComponent } =
    useAdd("customers", token);

  useEffect(() => {
    if (resetForm) {
      setFormData({
        firstname: "",
        lastname: "",
        father_name: "",
        phone: "",
        email: "",
        address: "",
      });
      setFormErrors({});
    }
    setResetForm(false);
  }, [resetForm, setResetForm]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const customerData = new FormData();
    Object.keys(formData).forEach((key) =>
      customerData.append(key, formData[key])
    );
    handleAdd(customerData);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid item xs={11} md={md}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            maxWidth: "600px",
            width: "100%",
            margin: "auto",
            padding: "40px",
            boxShadow: 4,
            borderRadius: "12px",
            background: "white",
            transition: "all 0.3s ease-in-out",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <PersonAddIcon sx={{ fontSize: "50px", color: "primary.main" }} />
            <Typography
              variant="h4"
              component="h1"
              sx={{ marginLeft: "10px", fontWeight: "bold" }}
            >
              {t("customer.addCustomer")}
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <FormMessageComponent />
          </Box>

          {/* First Name */}
          <TextField
            fullWidth
            type="text"
            variant="standard"
            label={t("customer.fName")}
            name="firstname"
            value={formData.firstname}
            error={!!formErrors.firstname}
            helperText={formErrors.firstname}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
          />

          {/* Last Name */}
          <TextField
            fullWidth
            type="text"
            variant="standard"
            label={t("customer.lName")}
            name="lastname"
            value={formData.lastname}
            error={!!formErrors.lastname}
            helperText={formErrors.lastname}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
          />

          {/* Father Name */}
          <TextField
            fullWidth
            type="text"
            variant="standard"
            label={t("customer.faName")}
            name="father_name"
            value={formData.father_name}
            error={!!formErrors.father_name}
            helperText={formErrors.father_name}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
          />

          {/* Phone */}
          <TextField
            fullWidth
            type="text"
            variant="standard"
            label={t("customer.phone")}
            name="phone"
            value={formData.phone}
            error={!!formErrors.phone}
            helperText={formErrors.phone}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
          />

          {/* Email */}
          <TextField
            fullWidth
            type="email"
            variant="standard"
            label={t("customer.email")}
            name="email"
            value={formData.email}
            error={!!formErrors.email}
            helperText={formErrors.email}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
          />

          {/* Address */}
          <TextField
            fullWidth
            type="text"
            variant="standard"
            label={t("customer.address")}
            name="address"
            value={formData.address}
            error={!!formErrors.address}
            helperText={formErrors.address}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
          />

          <Buttons onSubmit={handleSubmit} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddCustomer;
