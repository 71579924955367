import React from "react";
import { Route, Routes } from "react-router-dom";
import ExpenseIndex from "../../pages/dashboard/expense/ExpenseIndex";
import ExpenseList from "../../pages/dashboard/expense/expenseList/ExpenseList";
import AddExpense from "../../pages/dashboard/expense/addExpense/AddExpense";
import EditExpense from "../../pages/dashboard/expense/editExpense/EditExpense";

const ExpenseRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ExpenseIndex />}>
        <Route path="/" element={<ExpenseList />} />
        <Route path="/add-expense" element={<AddExpense />} />
        <Route path="/edit-expense" element={<EditExpense />} />
      </Route>
    </Routes>
  );
};

export default ExpenseRoutes;
