import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  TableContainer,
  Box,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";

import { CurrencyExchange as CurrencyIcon } from "@mui/icons-material";

import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context/AuthContext";
import ErrorPage from "../../../../components/errorPage/ErrorPage";
import TableSkeletonLoading from "../../../../components/tableSkeletonLoading/TableSkeletonLoading";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import "./style.css"; // Importing the CSS file
import GenericPdfDownloader from "./GenericPdfDownloader";

const iconStyle = { fontSize: 40, mb: 1, color: "#2e7d32" };

// LoanReports Component
const LoanReports = () => {
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [customers, setCustomers] = useState([]); // State for customer list
  const [selectedCustomer, setSelectedCustomer] = useState(null); // State for selected customer

  // State for dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRepayments, setSelectedRepayments] = useState(null);

  // Fetch data from the backend
  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["LoanReports"],
    `report/loan/?start_date=${startDate}&end_date=${endDate}&customer_id=${
      selectedCustomer?.id || ""
    }`, // Update query param
    token
  );

  // Fetch customers for autocomplete
  const { data: customerData, isLoading: isCustomerLoading } = useFetchObjects(
    ["Customers"],
    `customers/`, // Replace with your actual endpoint for fetching customers
    token
  );

  useEffect(() => {
    if (customerData) {
      setCustomers(customerData); // Set the customer data for autocomplete
    }
  }, [customerData]);

  useEffect(() => {
    refetch();
  }, [refetch, selectedCustomer, startDate, endDate]); // Add selectedCustomer to the dependency array

  // Handle filtering
  const handleFilter = () => {
    refetch();
  };

  const handleOpenDialog = (repaymentData) => {
    setSelectedRepayments(repaymentData);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if (isLoading) {
    return <TableSkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!data) {
    return <Typography>No loan reports available.</Typography>;
  }

  const {
    combined_totals_by_currency = {},
    current_repayment_details = [],
    past_repayment_details = [],
  } = data;

  return (
    <Grid container justifyContent={"center"} margin={"20px 0px"}>
      <Grid
        item
        xs={11}
        bgcolor={"#f9f9f9"}
        padding={"20px"}
        borderRadius={"10px"}
        boxShadow={"0 4px 12px rgba(0, 0, 0, 0.1)"}
      >
        {/* Title */}
        <Typography
          variant="h5"
          gutterBottom
          style={{ fontSize: "22px", fontWeight: "bold" }}
        >
          Loan Reports
        </Typography>

        {/* Date Filters */}
        <Grid container spacing={2} marginBottom={3}>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              fullWidth
              type="date"
              label="Start Date"
              variant="outlined"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              fullWidth
              type="date"
              label="End Date"
              variant="outlined"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              options={customers || []} // Use the fetched customer data
              getOptionLabel={(option) =>
                `${option.firstname} ${option.lastname}`
              } // Display customer name
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  label="Search by customer"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              onChange={(event, value) => {
                setSelectedCustomer(value); // Set selected customer
                setSearchTerm(value ? value.id : ""); // Update search term
              }}
              value={selectedCustomer} // Controlled value
              isOptionEqualToValue={(option, value) => option.id === value.id} // Compare options
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilter}
              >
                Apply Filter
              </Button>
            </Box>
          </Grid>
        </Grid>

        <GenericPdfDownloader
          downloadFileName="LoanReports.pdf"
          rootElementId="loanReportsContent"
        >
          {/* PDF Download Button */}

          {/* Loan Totals by Currency */}
          <Typography
            variant="h6"
            gutterBottom
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            Loan Totals by Currency
          </Typography>
          <Grid
            container
            spacing={2}
            marginBottom={4}
            justifyContent={"center"}
          >
            {Object.keys(combined_totals_by_currency).map((currency) => {
              const {
                total_past_loan_amount,
                total_current_loan_amount,
                total_repayment,
                total_combined_loan_amount,
                total_remaining_amount,
              } = combined_totals_by_currency[currency];

              return (
                <Grid item xs={12} md={4} key={currency}>
                  <Card
                    className="text-center bg-white p-3"
                    style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)" }}
                  >
                    <CardContent>
                      <CurrencyIcon sx={iconStyle} />
                      <Typography
                        variant="h6"
                        fontWeight={"bold"}
                        color="textSecondary"
                        style={{ fontSize: "18px" }}
                      >
                        {currency} - Combined Loan Amount
                      </Typography>
                      <Typography
                        variant="h5"
                        fontWeight={"bold"}
                        color="primary"
                        style={{ fontSize: "22px" }}
                      >
                        {total_combined_loan_amount?.toLocaleString()}{" "}
                        {currency}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color="textSecondary"
                        marginTop={1}
                      >
                        Past Loan Amount:{" "}
                        {total_past_loan_amount?.toLocaleString()} {currency}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color="textSecondary"
                        marginTop={1}
                      >
                        Current Loan Amount:{" "}
                        {total_current_loan_amount?.toLocaleString()} {currency}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color="textSecondary"
                        marginTop={1}
                      >
                        Total Repayments: {total_repayment?.toLocaleString()}{" "}
                        {currency}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color="textSecondary"
                        marginTop={1}
                      >
                        Remaining Amount:{" "}
                        {total_remaining_amount?.toLocaleString()} {currency}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>

          {/* Current Repayment Details */}
          <Typography
            variant="h6"
            gutterBottom
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            Current Loans Details
          </Typography>
          <TableContainer elevation={3} className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead className="table-dark">
                <tr>
                  <th>Customer</th>
                  <th>Loan ID</th>
                  <th>Loan Amount</th>
                  <th>Currency</th>
                  <th>Total Repayment</th>
                  <th>Remaining Amount</th>
                  <th>Loan Date</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {current_repayment_details.map((repayment, index) => {
                  const { firstname, lastname } = repayment.customer;
                  const loanId = repayment.loan_id;
                  const loanAmount = repayment.loan_amount;
                  const totalRepayment = repayment.total_repayment;
                  const remainingAmount = repayment.total_remaining_amount;
                  const currency = repayment.currency;
                  const loanDate = new Date(
                    repayment.loan_date
                  ).toLocaleDateString();

                  return (
                    <tr key={index}>
                      <td>{` ${firstname} ${lastname}` || "N/A"}</td>
                      <td>{loanId}</td>
                      <td>{loanAmount?.toLocaleString()}</td>
                      <td>{currency}</td>
                      <td>{totalRepayment?.toLocaleString()}</td>
                      <td>{remainingAmount?.toLocaleString()}</td>
                      <td>{loanDate}</td>
                      <td>
                        <Button
                          variant="outlined"
                          sx={{ textTransform: "capitalize" }}
                          onClick={() => handleOpenDialog(repayment.repayments)}
                        >
                          Detail
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TableContainer>

          {/* Past Repayment Details */}
          <Typography
            variant="h6"
            gutterBottom
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            Past Loans Details
          </Typography>
          <TableContainer elevation={3} className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead className="table-dark">
                <tr>
                  <th>Customer</th>
                  <th>Loan ID</th>
                  <th>Loan Amount</th>
                  <th>Currency</th>
                  <th>Total Repayment</th>
                  <th>Remaining Amount</th>
                  <th>Loan Date</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {past_repayment_details.map((repayment, index) => {
                  const { firstname, lastname } = repayment.customer;
                  const loanId = repayment.loan_id;
                  const loanAmount = repayment.loan_amount;
                  const totalRepayment = repayment.total_repayment;
                  const remainingAmount = repayment.total_remaining_amount;
                  const currency = repayment.currency;
                  const loanDate = new Date(
                    repayment.loan_date
                  ).toLocaleDateString();

                  return (
                    <tr key={index}>
                      <td>{` ${firstname} ${lastname}` || "N/A"}</td>
                      <td>{loanId}</td>
                      <td>{loanAmount?.toLocaleString()}</td>
                      <td>{currency}</td>
                      <td>{totalRepayment?.toLocaleString()}</td>
                      <td>{remainingAmount?.toLocaleString()}</td>
                      <td>{loanDate}</td>
                      <td>
                        <Button
                          variant="outlined"
                          sx={{ textTransform: "capitalize" }}
                          onClick={() => handleOpenDialog(repayment.repayments)} // Pass repayment data
                        >
                          Detail
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TableContainer>
        </GenericPdfDownloader>
        {/* Dialog for Repayment Details */}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Repayment Details</DialogTitle>
          <DialogContent>
            <List>
              {selectedRepayments?.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`Amount: ${item.amount}`}
                    secondary={`Payment Date: ${new Date(
                      item.payment_date
                    ).toLocaleString()}`}
                  />
                  {index < selectedRepayments?.length - 1 && <Divider />}
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default LoanReports;
