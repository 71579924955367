// src/api/useUpdate.js
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState, useEffect } from "react";
import { Grid, Button, CircularProgress, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useUpdate = (queryKey, token, redirectPath) => {
  const { t } = useTranslation(); 
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [formMessageOpen, setFormMessageOpen] = useState(false);
  const [formMessageSeverity, setFormMessageSeverity] = useState("success");
  const navigate = useNavigate();
  const updateMutation = useMutation({
    mutationFn: async ({ id, data }) => {
      setLoading(true);
      const headers = token ? { Authorization: `Token ${token}` } : {};
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/${queryKey}/${id}/`,
          data,
          { headers }
        );
        return response.data;
      } catch (error) {
        throw new Error(
          error.response?.data?.detail || t("useupdate.updateFailed")
        );
      }
    },
    onSuccess: () => {
      setLoading(false);
      setFormMessage( t("useupdate.dataUpdatedSuccess"));
      setFormMessageSeverity( t("useupdate.success"));
      setFormMessageOpen(true);
      queryClient.invalidateQueries(queryKey);
      if (redirectPath) {
        navigate(redirectPath);
      } else {
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);
      setFormMessage(error.message);
      setFormMessageSeverity(t("useupdate.error"));
      setFormMessageOpen(true);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (formMessageOpen) {
      const timer = setTimeout(() => {
        setFormMessageOpen(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [formMessageOpen]);

  const handleUpdate = (id, data) => {
    updateMutation.mutate({ id, data });
  };

  const FormMessageComponent = () => (
    <Box
      sx={{
        display: formMessageOpen ? "flex" : "none",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: formMessageSeverity === "success" ? "green" : "red",
        color: "#fff",
        padding: "10px 15px",
        borderRadius: "4px",
        mt: 2,
        position: "relative",
      }}
    >
      <Box>{formMessage}</Box>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setFormMessageOpen(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );

  const Buttons = ({ onSubmit }) => (
    <Grid container justifyContent={"end"} mt={2}>
      <Button
        variant="contained"
        color="primary"
        disabled={loading}
        sx={{ mr: 2 }}
      >
        {t("useupdate.cancel")}
      </Button>
      <Button
        variant="contained"
        onClick={onSubmit}
        color="primary"
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} />}
        style={{margin:"0em 1em"}}
      >
        {loading ?  t("useupdate.updateing") : t("useupdate.update")}
      </Button>
    </Grid>
  );

  return {
    handleUpdate,
    Buttons,
    FormMessageComponent,
  };
};

export default useUpdate;
