import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  Autocomplete,
  Chip,
  MenuItem,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useAuth } from "../../../../context/AuthContext";
import useFetchObjects from "../../../../api/useFetchObjects";
import useFetchObject from "../../../../api/useFetchObject";
import { useLocation } from "react-router-dom";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";

const EditLoan = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const location = useLocation();
  const loan_payment = location?.state?.item;

  const { data, isLoading, isError } = useFetchObject(
    ["loan-payment"],
    "loan-payment",
    loan_payment?.id,
    token
  );

  const [formData, setFormData] = useState({
    loan: data?.loan || null,
    amount: data?.amount || "",
    currency: data?.currency || "AFG",
    payment_date: data?.payment_date || "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [loanAmount, setLoanAmount] = useState(0); // Store the loan amount
  const [loanData, setLoanData] = useState([]); // Store loan data

  const { handleUpdate, Buttons, FormMessageComponent } = useUpdate(
    "loan-payment",
    token
  ); // Adjust endpoint for loan payment

  const {
    data: fetchedLoanData,
    isLoading: isLoadingLoan,
    isError: isErrorLoan,
  } = useFetchObjects(["loan"], "loan/", token);

  useEffect(() => {
    if (fetchedLoanData) {
      setLoanData(fetchedLoanData);
    }
  }, [fetchedLoanData]);

  useEffect(() => {
    if (data) {
      setFormData({
        loan: data.loan,
        amount: data.amount,
        currency: "",
        payment_date: data.payment_date,
      });
      setLoanAmount(data.loan?.loan_amount || 0); // Initialize loan amount from fetched data
    }
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    // Validation
    if (!formData.loan) errors.loan = t("loan.loanReq"); // Ensure loan is selected
    if (!formData.amount) errors.amount = t("loan.amountReq");
    if (formData.amount > loanAmount) errors.amount = t("loan.errorAmount");

    if (Object.keys(errors).length === 0) {
      const loanData = new FormData();
      loanData.append("loan", formData.loan.id); // Use the ID for foreign key
      loanData.append("amount", formData.amount);
      loanData.append("payment_date", formData.payment_date);
      handleUpdate(loan_payment.id, loanData);
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    if (formErrors[field]) {
      setFormErrors({ ...formErrors, [field]: "" });
    }

    // Update the loan amount based on the selected loan
    if (field === "loan" && value) {
      setLoanAmount(value.loan_amount); // Set the loan amount
      setFormData({ ...formData, amount: "", loan: value }); // Reset amount field, maintain loan selection
    }
  };

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid item xs={11} md={6}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            maxWidth: "600px",
            width: "100%",
            margin: "auto",
            padding: "40px",
            boxShadow: 4,
            borderRadius: "12px",
            background: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <PersonAddIcon sx={{ fontSize: "50px", color: "primary.main" }} />
            <Typography
              variant="h4"
              component="h1"
              sx={{ marginLeft: "10px", fontWeight: "bold" }}
            >
              {t("loan.updateLoan")}
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <FormMessageComponent />
          </Box>

          {/* Loan Autocomplete */}
          <Autocomplete
            options={loanData}
            getOptionLabel={(option) =>
              `${option.customer_display.firstname} ${
                option.customer_display.lastname
              } --    Bill No:  ${option.display_bill}  -- (Loan Amount: ${
                option.loan_amount
              } ${option.currency === "AFG" ? "؋" : ""}${
                option.currency === "USD" ? "$" : ""
              }${option.currency === "PKR" ? "R" : ""})`
            }
            onChange={(event, value) => handleChange("loan", value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("loan.loan")}
                variant="standard"
                error={!!formErrors.loan}
                helperText={formErrors.loan}
                fullWidth
                sx={{ marginBottom: "20px" }}
              />
            )}
          />

          {/* Loan Amount Chip */}
          {formData.loan && (
            <Chip
              label={
                t("loan.loanAmount1") +
                `${loanAmount} ${formData?.currency === "AFG" ? "؋" : ""}
              ${formData?.currency === "USD" ? "$" : ""}
              ${formData?.currency === "PKR" ? "R" : ""}`
              }
              variant="outlined"
              color="primary"
              sx={{
                marginBottom: "20px",
                backgroundColor: "#f0c7bd",
                color: "#333130",
                borderRadius: "10px",
              }}
            />
          )}

          {/* Amount Field */}
          <TextField
            fullWidth
            type="number"
            variant="standard"
            label={t("loan.amount")}
            name="amount"
            value={formData.amount}
            error={!!formErrors.amount}
            helperText={formErrors.amount}
            onChange={(e) => handleChange("amount", e.target.value)}
            inputProps={{
              max: loanAmount, // Set max value based on selected loan
            }}
            sx={{ mt: 3 }}
          />

          <TextField
            select
            fullWidth
            variant="standard"
            label={t("purchase.currency")}
            name="currency"
            value={formData.currency}
            onChange={(e) => handleChange("currency", e.target.value)}
            sx={{ mt: 3 }}
          >
            <MenuItem value="AFG"> {t("purchase.afg")}</MenuItem>
            <MenuItem value="USD"> {t("purchase.usd")}</MenuItem>
            <MenuItem value="PKR"> {t("purchase.pkr")}</MenuItem>
          </TextField>

          {/* Payment Date with Shrink Effect */}
          <TextField
            fullWidth
            type="datetime-local"
            variant="standard"
            label={t("loan.paymentDate")}
            name="payment_date"
            value={formData.payment_date}
            error={!!formErrors.payment_date}
            helperText={formErrors.payment_date}
            onChange={(e) => handleChange("payment_date", e.target.value)}
            sx={{ mt: 3 }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Buttons onSubmit={handleSubmit} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditLoan;
