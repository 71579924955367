import React from "react";
import { Container, Grid, Stack, Typography } from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import StoreIcon from "@mui/icons-material/Store";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Define the icon style with individual colors for better visual contrast
const iconStyle = (color) => ({
  fontSize: "5rem",
  color: color,
});

// Items array with updated icon colors

const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const items = [
    {
      title: t("home.products"),
      icon: <CardGiftcardIcon sx={iconStyle("#8B34AD")} />,
      path: "/products",
    },
    {
      title: t("home.inventory"),
      icon: <ListAltIcon sx={iconStyle("#5A9C6E")} />,
      path: "/inventory",
    },
    {
      title: t("home.customers"),
      icon: <ReduceCapacityIcon sx={iconStyle("#F5A623")} />,
      path: "/customers",
    },
    {
      title: t("home.purchases"),
      icon: <StoreIcon sx={iconStyle("#F55A4E")} />,
      path: "/purchases",
    },
    {
      title: t("home.sales"),
      icon: <ReceiptIcon sx={iconStyle("#50A0E8")} />,
      path: "/sales",
    },
    {
      title: t("home.expenses"),
      icon: <ExitToAppIcon sx={iconStyle("#FF715E")} />,
      path: "/expense",
    },
    {
      title: t("home.loans"),
      icon: <CreditScoreIcon sx={iconStyle("#FFB238")} />,
      path: "/loans",
    },
    {
      title: t("home.suppliers"),
      icon: <GroupAddIcon sx={iconStyle("#8E44AD")} />,
      path: "/suppliers",
    },

    {
      title: t("home.reports"),
      icon: <AssessmentIcon sx={iconStyle("#0563f0")} />,
      path: "/reports",
    },

    {
      title: "Past Loan",
      icon: <AssessmentIcon sx={iconStyle("#0563f0")} />,
      path: "/past-loan",
    },
  ];

  return (
    <Container sx={{ mt: 4 }}>
      <Grid
        container
        gap={3}
        justifyContent={"center"}
        margin={"0px auto"}
        sx={{ width: { xs: "100%", md: "80%" } }}
      >
        {items.map((item, index) => (
          <Grid
            key={index}
            item
            xs={3}
            sm={2}
            md={1.3}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(item.path);
            }}
          >
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                bgcolor: "#E5E7EB", // Neutral background for stack
                padding: "10px 20px",
                borderRadius: "7px",
                border: "1px solid #c5cbd4",
                transition: "transform 0.3s ease, background-color 0.3s ease",
                "&:hover": {
                  transform: "translateY(-5px)",
                  bgcolor: "#D1D5DB", // Change background color on hover
                },
              }}
            >
              {item.icon}
            </Stack>
            <Typography align="center" mt={1} fontWeight="bold" color="#2D3748">
              {item.title}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Home;
