import React from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "../../pages/dashboard/auth/signIn/SignIn";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<SignIn />} />
    </Routes>
  );
};

export default AuthRoutes;
