import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  TableContainer,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import { CurrencyExchange as CurrencyIcon } from "@mui/icons-material";
import { Button } from "react-bootstrap";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context/AuthContext";
import ErrorPage from "../../../../components/errorPage/ErrorPage";
import TableSkeletonLoading from "../../../../components/tableSkeletonLoading/TableSkeletonLoading";
import SearchIcon from "@mui/icons-material/Search";
import "./style.css"; // Importing the CSS file
import { useTranslation } from "react-i18next";
const iconStyle = { fontSize: 50, mb: 1, color: "#0288d1" };

// InventoryReports Component
const InventoryReports = () => {
  const {t}=useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch data from the backend
  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["InventoryReports"],
    `report/inventory/?start_date=${startDate}&end_date=${endDate}&search=${searchTerm}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [searchTerm, refetch]);

  // Handle filtering
  const handleFilter = () => {
    refetch();
  };

  if (isLoading) {
    return <TableSkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!data) {
    return <Typography>{t("report.noInventory")}</Typography>;
  }

  const { inventory, totals_by_currency, overall_totals_by_currency } = data;

  return (
    <Grid container justifyContent={"center"} margin={"20px 0px"}>
      <Grid
        item
        xs={11}
        bgcolor={"white"}
        padding={"10px 20px"}
        borderRadius={"10px"}
      >
        <Grid container>
          <Typography variant="h4" gutterBottom>
          {t("report.inventoryReport")}
          </Typography>

          {/* Date Filters */}
          <Grid container spacing={2} marginBottom={4} mt={1}>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                type="date"
                label= {t("report.startDate")}
                variant="outlined"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                type="date"
                label={t("report.endDate")}
                variant="outlined"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                fullWidth
                placeholder={t("report.searchByProduct")}
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Button
                  variant="primary"
                  onClick={handleFilter}
                  className="w-100"
                >
                  {t("report.applyFilter")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Grand Total Section per Currency */}
        <Grid container spacing={2} marginBottom={4} justifyContent={"center"}>
          {Object.entries(overall_totals_by_currency).map(([currency, totals], index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} md={6}>
                <Card className="text-center bg-light p-3">
                  <CardContent>
                    <CurrencyIcon className="rotating-icon" sx={iconStyle} />
                    <Typography
                      variant="h5"
                      fontWeight={"bold"}
                      color="textSecondary"
                    >
                     {t("report.gTotalW")}({currency})
                    </Typography>
                    <Typography variant="h4" fontWeight={"bold"} color="primary">
                      {totals?.grand_total_wholesale?.toLocaleString()} {currency}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card className="text-center bg-light p-3">
                  <CardContent>
                    <CurrencyIcon className="rotating-icon" sx={iconStyle} />
                    <Typography
                      variant="h5"
                      fontWeight={"bold"}
                      color="textSecondary"
                    >
                      {t("report.gTotalR")}({currency})
                    </Typography>
                    <Typography variant="h4" fontWeight={"bold"} color="primary">
                      {totals?.grand_total_retail?.toLocaleString()} {currency}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>

        {/* Inventory Table */}
        <Typography variant="h5" gutterBottom>
          
          {t("report.inventoryDetails")}
        </Typography>
        <TableContainer elevation={3} className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-dark">
              <tr>
                <th>{t("report.productName")}</th>
                <th>{t("report.totalQuan")}</th>
                <th>{t("report.unitPrice")}</th>
                <th>{t("report.whPrice")}</th>
                <th>{t("report.rePrice")}</th>
                <th>{t("report.totalWhPrice")}</th>
                <th>{t("report.totalRePrice")}</th>
                <th>{t("report.currency")}</th>
              </tr>
            </thead>
            <tbody>
              {inventory?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.product_name}</td>
                  <td>{item?.total_quantity}</td>
                  <td>؋ {item?.unit_price?.toLocaleString()}</td>
                  <td>؋ {item?.wholesale_price?.toLocaleString()}</td>
                  <td>؋ {item?.retail_sale_price?.toLocaleString()}</td>
                  <td>؋ {item?.total_wholesale_price?.toLocaleString()}</td>
                  <td>؋ {item?.total_retail_price?.toLocaleString()}</td>
                  <td>{item?.currency}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default InventoryReports;
