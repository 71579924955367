import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      language: require("./English/LanguageSelector.json"),
      home: require("./English/Home/Home.json"),
      signin: require("./English/Signin/Signin.json"),
      signup: require("./English/Signup/Singnup.json"),
      product: require("./English/Product/Product.json"),
      useadd: require("./English/Api/UseAdd.json"),
      usedelete: require("./English/Api/UseDelete.json"),
      useupdate: require("./English/Api/UseUpdate.json"),
      purchase: require("./English/Purchase/Purchase.json"),
      inventory: require("./English/Inventory/Inventory.json"),
      customer: require("./English/Customer/Customer.json"),
      sales: require("./English/Sales/Sales.json"),
      expense: require("./English/Expense/Expense.json"),
      supplier: require("./English/Supplier/Supplier.json"),
      loan: require("./English/Loan/Loan.json"),
      report: require("./English/report/report.json"),
      invoice: require("./English/Invoice/Invoice.json"),
    },
  },
  dr: {
    translation: {
      language: require("./Dari/LanguageSelector.json"),
      home: require("./Dari/Home/Home.json"),
      signin: require("./Dari/Signin/Signin.json"),
      signup: require("./Dari/Signup/Singnup.json"),
      product: require("./Dari/Product/Product.json"),
      useadd: require("./Dari/Api/UseAdd.json"),
      usedelete: require("./Dari/Api/UseDelete.json"),
      useupdate: require("./Dari/Api/UseUpdate.json"),
      purchase: require("./Dari/Purchase/Purchase.json"),
      inventory: require("./Dari/Inventory/Inventory.json"),
      customer: require("./Dari/Customer/Customer.json"),
      sales: require("./Dari/Sales/Sales.json"),
      expense: require("./Dari/Expense/Expense.json"),
      supplier: require("./Dari/Supplier/Supplier.json"),
      loan: require("./Dari/Loan/Loan.json"),
      report: require("./Dari/report/report.json"),
      invoice: require("./Dari/Invoice/Invoice.json"),
    },
  },
  ps: {
    translation: {
      language: require("./Pashto/LanguageSelector.json"),
      home: require("./Pashto/Home/Home.json"),
      signin: require("./Pashto/Signin/Signin.json"),
      signup: require("./Pashto/Signup/Singnup.json"),
      product: require("./Pashto/Product/Product.json"),
      useadd: require("./Pashto/Api/UseAdd.json"),
      usedelete: require("./Pashto/Api/UseDelete.json"),
      useupdate: require("./Pashto/Api/UseUpdate.json"),
      purchase: require("./Pashto/Purchase/Purchase.json"),
      inventory: require("./Pashto/Inventory/Inventory.json"),
      customer: require("./Pashto/Customer/Customer.json"),
      sales: require("./Pashto/Sales/Sales.json"),
      expense: require("./Pashto/Expense/Expense.json"),
      supplier: require("./Pashto/Supplier/Supplier.json"),
      loan: require("./Pashto/Loan/Loan.json"),
      report: require("./Pashto/report/report.json"),
      invoice: require("./Pashto/Invoice/Invoice.json"),
    },
  },
};

// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
