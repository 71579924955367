import React from "react";
import { Route, Routes } from "react-router-dom";
import CustomerIndex from "../../pages/dashboard/customer/CustomerIndex";
import CustomerList from "../../pages/dashboard/customer/customerList/CustomerList";
import AddCustomer from "../../pages/dashboard/customer/addCustomer/AddCustomer";
import EditCustomer from "../../pages/dashboard/customer/editCustomer/EditCutomer";

const CustomerRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<CustomerIndex />}>
        <Route path="/" element={<CustomerList />} />
        <Route path="/add-customer" element={<AddCustomer />} />
        <Route path="/edit-customer" element={<EditCustomer />} />
      </Route>
    </Routes>
  );
};

export default CustomerRoutes;
