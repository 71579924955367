import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  TextField,
  TableContainer,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { CurrencyExchange as CurrencyIcon } from "@mui/icons-material";
import { Button } from "react-bootstrap";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context/AuthContext";
import ErrorPage from "../../../../components/errorPage/ErrorPage";
import TableSkeletonLoading from "../../../../components/tableSkeletonLoading/TableSkeletonLoading";
import { useTranslation } from "react-i18next";

import "./style.css"; // Importing the CSS file

const iconStyle = { fontSize: 50, mb: 1, color: "#2e7d32" };

// ReportsList Component for Purchases
const PurchasesReports = () => {
  const {t}=useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch data from the backend
  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["PurchaseReports"],
    `report/purchases/?start_date=${startDate}&end_date=${endDate}&search=${searchTerm}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [refetch, searchTerm]);

  // Handle filtering
  const handleFilter = () => {
    refetch();
  };

  if (isLoading) {
    return <TableSkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!data) {
    return <Typography>{t("report.noPurchase")}</Typography>;
  }

  const { purchase_totals, product_purchases } = data;

  return (
    <Grid container justifyContent={"center"} margin={"20px 0px"}>
      <Grid
        item
        xs={11}
        bgcolor={"white"}
        padding={"10px 20px"}
        borderRadius={"10px"}
      >
        <Grid container>
          <Typography variant="h4" gutterBottom>
          {t("report.purchaseReport")}
          </Typography>

          {/* Date Filters */}
          <Grid container spacing={2} marginBottom={4} mt={2}>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                type="date"
                label={t("report.startDate")}
                variant="outlined"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                type="date"
                label={t("report.endDate")}
                variant="outlined"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                fullWidth
                placeholder={t("report.searchByPS")}
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Button
                  variant="primary"
                  onClick={handleFilter}
                  className="w-100"
                >
                  {t("report.applyFilter")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Total Purchase Amount by Currency */}
        <Grid container spacing={2} marginBottom={4} justifyContent={"center"}>
          {Object.keys(purchase_totals).map((currency) => (
            <Grid item xs={12} md={4} key={currency}>
              <Card className="text-center bg-light p-3">
                <CardContent>
                  <CurrencyIcon className="rotating-icon" sx={iconStyle} />
                  <Typography
                    variant="h5"
                    fontWeight={"bold"}
                    color="textSecondary"
                  >
                     {t("report.totalPurchase")}{currency}
                  </Typography>
                  <Typography variant="h4" fontWeight={"bold"} color="primary">
                    ؋ {purchase_totals[currency]?.toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Product Purchases by Currency */}
        <Typography variant="h5" gutterBottom>
          
          {t("report.productPurchase")}
        </Typography>
        <TableContainer elevation={3} className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-dark">
              <tr>
                <th>   {t("report.productName")}</th>
                <th>   {t("report.currency")}</th>
                <th>   {t("report.quanPurchase")}</th>
                <th>   {t("report.unitPrice")}</th>
                <th>   {t("report.totalPrice")}</th>
              </tr>
            </thead>
            <tbody>
              {product_purchases?.map((purchase, index) => (
                <tr key={index}>
                  <td>{purchase?.product__name}</td>
                  <td>{purchase?.currency}</td>
                  <td>{purchase?.total_quantity}</td>
                  <td>؋ {purchase?.unit_price?.toLocaleString()}</td>
                  <td>؋ {purchase?.total_price?.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PurchasesReports;
