import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Skeleton,
} from "@mui/material";

const TableSkeletonLoading = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ p: 2 }}>
      <TableContainer component={Paper} sx={{ width: "100%", mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 5 }).map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default TableSkeletonLoading;
