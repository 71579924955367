import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCustomer from "../../customer/addCustomer/AddCustomer";
import AddPurchase from "../../purchase/addPurchase/AddPurchase";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context/AuthContext";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddSales = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState();
  const [component, setComponent] = useState("");

  const [currency, setCurrency] = useState("AFG"); // Set default currency to AFG
  const [formData, setFormData] = useState({
    sales_type: "Whole Sales",

    bill_no: "",
    customer: "",
    received_money: 0,
    is_paid: false,
  });

  const [customerID, setCustomerID] = useState();
  const [productName, setProductName] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [formErrors, setFormErrors] = useState(null);

  // Fetch customers and inventory
  const {
    data: customers,
    isLoading: isLoadingCustomers,
    isError: isErrorCustomers,
  } = useFetchObjects(["customers"], "customers/", token);

  const {
    data: loanData,
    isLoading: isLoadingLoan,
    isError: isErrorLoan,
    refetch: refetchLoan,
  } = useFetchObjects(["loans"], `loan/?customer=${customerID}`, token);

  const {
    data: inventoryData,
    isLoading: isLoadingInventory,
    isError: isErrorInventory,
    refetch: refetchInventory,
  } = useFetchObjects(["inventory"], `inventory/?search=${productName}`, token);

  const { handleAdd, resetForm, FormMessageComponent } = useAdd("sales", token);

  useEffect(() => {
    refetchLoan();
  }, [customerID, refetchLoan]);

  useEffect(() => {
    refetchInventory();
  }, [productName]);

  useEffect(() => {
    if (resetForm) {
      navigate("/sales");
    }
  }, [resetForm, navigate]);

  // Handle currency change
  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  // Handle sales type change (Retail or Wholesale)
  const handleSalesTypeChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, sales_type: value });
  };

  // Handle sales type change (Retail or Wholesale)
  const handleBillChange = (e) => {
    setFormData({ ...formData, bill_no: e.target.value });
  };

  // Handle quantity change for products
  const handleQuantityChange = (index, value) => {
    setSelectedProducts((prev) => {
      const updatedProducts = [...prev];
      const newQuantity = Math.max(1, value); // Ensure at least 1
      updatedProducts[index].quantity = newQuantity;

      // Update total price based on sales type and currency
      updatedProducts[index].total_price = (
        newQuantity *
        (formData.sales_type === "Whole Sales"
          ? updatedProducts[index][`whole_sale_price_${currency.toLowerCase()}`]
          : updatedProducts[index][
              `retail_sale_price_${currency.toLowerCase()}`
            ])
      ).toFixed(2);

      return updatedProducts;
    });
  };

  // Handle price change (adjust based on sale type and currency)
  const handlePriceChange = (index, value, priceType) => {
    setSelectedProducts((prev) => {
      const updatedProducts = [...prev];
      updatedProducts[index][priceType] = value;

      updatedProducts[index].total_price = (
        updatedProducts[index].quantity *
        (formData.sales_type === "Whole Sales"
          ? updatedProducts[index][`whole_sale_price_${currency.toLowerCase()}`]
          : updatedProducts[index][
              `retail_sale_price_${currency.toLowerCase()}`
            ])
      ).toFixed(2);

      return updatedProducts;
    });
  };

  // Calculate total sale amount
  const calculateTotal = () => {
    return selectedProducts.reduce(
      (sum, product) => sum + parseFloat(product.total_price),
      0
    );
  };

  // Handle product selection from Autocomplete
  const handleProductSelect = (event, value) => {
    const product = value;
    if (!product) return;

    // Check if the product with the same currency is already in the selected products
    const existingProductIndex = selectedProducts.findIndex(
      (p) => p.id === product.product && p.currency === currency
    );

    if (existingProductIndex >= 0) {
      // If product already exists with the same currency, increment its quantity
      setSelectedProducts((prev) => {
        const updatedProducts = [...prev];
        const newQuantity = updatedProducts[existingProductIndex].quantity + 1;
        updatedProducts[existingProductIndex].quantity = newQuantity;

        updatedProducts[existingProductIndex].total_price = (
          newQuantity *
          (formData.sales_type === "Retail Sales"
            ? updatedProducts[existingProductIndex][
                `retail_sale_price_${currency.toLowerCase()}`
              ]
            : updatedProducts[existingProductIndex][
                `whole_sale_price_${currency.toLowerCase()}`
              ])
        ).toFixed(2);

        return updatedProducts;
      });
    } else {
      // If product does not exist or currency is different, add it to selected products
      const productToAdd = {
        id: product.product,
        name: product.product_display,
        buy_price: product.unit_price,
        currency: product.currency,
        quantity: 1,
        retail_sale_price_afg: product.retail_sale_price_in_afg,
        retail_sale_price_usd: product.retail_sale_price_in_usd,
        retail_sale_price_pkr: product.retail_sale_price_in_pkr,
        whole_sale_price_afg: product.whole_sale_price_in_afg,
        whole_sale_price_usd: product.whole_sale_price_in_usd,
        whole_sale_price_pkr: product.whole_sale_price_in_pkr,
        inventory_quantity: product.item_quantity,
        total_price:
          formData.sales_type === "Retail Sales"
            ? product[`retail_sale_price_${currency.toLowerCase()}`]
            : product[`whole_sale_price_${currency.toLowerCase()}`],
      };

      setSelectedProducts((prev) => [...prev, productToAdd]);
    }
  };

  const handleRemoveProduct = (index) => {
    setSelectedProducts((prev) => prev.filter((_, i) => i !== index));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const saleItemsData = selectedProducts.map((product) => ({
      product: product.id,
      quantity: product.quantity,
      per_price:
        formData.sales_type === "Whole Sales"
          ? product[`whole_sale_price_${currency.toLowerCase()}`]
          : product[`retail_sale_price_${currency.toLowerCase()}`],
    }));

    const saleData = {
      ...formData,
      currency: currency,
      total_sale_amount: calculateTotal().toFixed(2),
      sale_items: saleItemsData,
    };

    if (formData.sales_type === "Whole Sales" && formData.customer === "") {
      setFormErrors(t("sales.errorCustomer"));
      return;
    }

    handleAdd(saleData);
  };

  let totalUSDLoan = 0;
  let totalAFGLoan = 0;
  let totalPKRLoan = 0;

  if (loanData && loanData.length) {
    console.log("Loanddada ");
    loanData?.map((loan) => {
      return {
        usd: (totalUSDLoan += loan.currency === "USD" && loan.loan_amount),
        afg: (totalAFGLoan += loan.currency === "AFG" && loan.loan_amount),
        pkr: (totalPKRLoan += loan.currency === "PKR" && loan.loan_amount),
      };
    });
  }

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <Grid container justifyContent="center" spacing={2} margin={"0px"} mb={2}>
      <Grid item xs={12} md={10}>
        <Card sx={{ boxShadow: 3, borderRadius: "12px", overflow: "hidden" }}>
          <CardHeader
            title={t("sales.addSales")}
            titleTypographyProps={{
              variant: "h4",
              fontWeight: "bold",
              textAlign: "center",
            }}
            sx={{ backgroundColor: "#1976d2", color: "#fff" }}
          />
          <CardContent>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid
                  container
                  spacing={3}
                  padding={"10px 25px"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {t("sales.salesType")}
                      </FormLabel>
                      <RadioGroup
                        row
                        value={formData.sales_type}
                        onChange={handleSalesTypeChange}
                      >
                        <FormControlLabel
                          value="Whole Sales"
                          control={<Radio />}
                          label={t("sales.whleSales")}
                        />
                        <FormControlLabel
                          value="Retail Sales"
                          control={<Radio />}
                          label={t("sales.retailSales")}
                        />
                      </RadioGroup>
                    </FormControl>

                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {t("sales.currencyType")}
                      </FormLabel>
                      <RadioGroup
                        row
                        value={currency}
                        onChange={handleCurrencyChange}
                      >
                        <FormControlLabel
                          value="AFG"
                          control={<Radio />}
                          label={t("sales.afg")}
                        />
                        <FormControlLabel
                          value="USD"
                          control={<Radio />}
                          label={t("sales.usd")}
                        />
                        <FormControlLabel
                          value="PKR"
                          control={<Radio />}
                          label={t("sales.pkr")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {/* Autocomplete for adding products */}
                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      spacing={2}
                      mt={2}
                    >
                      <Grid item xs={12} md={6} display={"flex"}>
                        <Autocomplete
                          fullWidth
                          options={inventoryData || []}
                          getOptionLabel={(option) => option.product_display}
                          onChange={handleProductSelect}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("sales.addProduct")}
                              fullWidth
                              size="small"
                            />
                          )}
                        />
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setComponent(1);
                            handleOpenDialog();
                          }}
                        >
                          New
                        </Button>
                      </Grid>

                      <Grid item xs={12} md={5} display={"flex"}>
                        {formData.sales_type === "Whole Sales" && (
                          <Autocomplete
                            size="small"
                            fullWidth
                            options={customers || []}
                            getOptionLabel={(option) =>
                              `${option.firstname} ${option.lastname}`
                            }
                            onChange={(e, newValue) => {
                              setCustomerID(newValue.id);
                              setFormData({
                                ...formData,
                                customer: newValue?.id || "",
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("sales.customer")}
                              />
                            )}
                          />
                        )}

                        <Button
                          variant="outlined"
                          onClick={() => {
                            setComponent(2);
                            handleOpenDialog();
                          }}
                        >
                          New
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  padding={"10px 25px"}
                  justifyContent={"space-between"}
                  spacing={2}
                >
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label={t("sales.billnum")}
                      name="bill_no"
                      value={formData.bill_no}
                      onChange={handleBillChange}
                      size="small"
                    />
                  </Grid>
                  {loanData && (
                    <Grid
                      item
                      xs={12}
                      sm={7.5}
                      display={"flex"}
                      borderRadius={"3px"}
                      mt={1}
                      sx={
                        {
                          // color: totalLoanAmount !== 0 ? "red" : "green",
                        }
                      }
                    >
                      <Grid
                        container
                        fontWeight={"bold"}
                        ml={1}
                        justifyContent={"space-between"}
                      >
                        <span>
                          {t("sales.remainingLoan")}{" "}
                          {loanData[0]?.customer_display?.firstname}{" "}
                          {loanData[0]?.customer_display?.lastname}:
                        </span>
                        <span>
                          {t("sales.usd")}: {totalUSDLoan}
                        </span>
                        <span>
                          {t("sales.afg")}: {totalAFGLoan}
                        </span>
                        <span>
                          {t("sales.pkr")}: {totalPKRLoan}
                        </span>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid container padding={"0px 25px"} display={"flex"}>
                  <Typography color={"red"}>{formErrors}</Typography>
                  <Typography color={"red"}>
                    {" "}
                    <FormMessageComponent />
                  </Typography>
                </Grid>
                {/* Bootstrap Table for displaying selected products */}
                <Grid item xs={12}>
                  <Table striped bordered hover responsive>
                    <thead
                      className="thead-dark"
                      style={{ backgroundColor: "gray" }}
                    >
                      <tr>
                        <th> {t("sales.product")}</th>
                        <th> {t("sales.quantity")}</th>
                        <th> {t("sales.buyPrice")}</th>
                        <th>
                          {formData.sales_type === "Whole Sales"
                            ? t("sales.whleSalesPrice")
                            : t("sales.retailPrice")}{" "}
                          {`(${currency})`}
                        </th>
                        <th> {t("sales.totalPrice")}</th>
                        <th> {t("sales.avaStok")}</th>
                        <th> {t("sales.action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedProducts.map((product, index) => (
                        <tr key={index}>
                          <td>{product.name}</td>
                          <td>
                            <TextField
                              type="number"
                              size="small"
                              value={product.quantity}
                              onChange={(e) =>
                                handleQuantityChange(index, e.target.value)
                              }
                              sx={{ width: { xs: "12vw", md: "10vw" } }}
                            />
                          </td>
                          <td>
                            {product.buy_price} {product.currency}
                          </td>
                          <td>
                            <TextField
                              type="number"
                              size="small"
                              value={
                                formData.sales_type === "Whole Sales"
                                  ? product[
                                      `whole_sale_price_${currency.toLowerCase()}`
                                    ]
                                  : product[
                                      `retail_sale_price_${currency.toLowerCase()}`
                                    ]
                              }
                              onChange={(e) =>
                                handlePriceChange(
                                  index,
                                  e.target.value,
                                  formData.sales_type === "Whole Sales"
                                    ? `whole_sale_price_${currency.toLowerCase()}`
                                    : `retail_sale_price_${currency.toLowerCase()}`
                                )
                              }
                              sx={{ width: { xs: "15vw", md: "12vw" } }}
                            />
                          </td>
                          <td>{product.total_price}</td>
                          <td>{product.inventory_quantity}</td>
                          <td>
                            <IconButton
                              onClick={() => handleRemoveProduct(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Grid>

                {/* Total and Customer Details */}
                <Grid
                  container
                  mt={2}
                  padding={"0px 25px"}
                  justifyContent={"start"}
                  gap={3}
                >
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      size="small"
                      label={t("sales.receivedMoey")}
                      disabled={formData.sales_type === "Retail Sales"}
                      value={
                        formData.sales_type === "Whole Sales"
                          ? formData.received_money
                          : calculateTotal().toFixed(2)
                      }
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          received_money: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      size="small"
                      label={t("sales.totalAmunt")}
                      value={calculateTotal().toFixed(2)}
                      disabled
                    />
                  </Grid>
                </Grid>

                {/* Action buttons */}
                <Grid item xs={12} textAlign="end" mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginRight: "10px" }}
                  >
                    {t("sales.print")}
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    type="submit"
                    style={{ margin: "0em 1em" }}
                  >
                    {t("sales.completeSale")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Dialog open={openDialog} onClose={handleOpenDialog}>
        <DialogContent>
          {component === 1 ? <AddPurchase md={12} /> : <AddCustomer md={12} />}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleOpenDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AddSales;
