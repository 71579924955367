import { Grid } from "@mui/material";
import React from "react";

import { Outlet } from "react-router-dom";
import ReportsNavBar from "./reportsNavBar/ReportsNavBar";

const ReportsIndex = () => {
  return (
    <Grid container>
      <ReportsNavBar />
      <Outlet />
    </Grid>
  );
};

export default ReportsIndex;
