import React from "react";
import { Route, Routes } from "react-router-dom";
import SalesIndex from "../../pages/dashboard/sales/SalesIndex";
import AddSales from "../../pages/dashboard/sales/addSales/AddSales";
import SalesList from "../../pages/dashboard/sales/salesList/SalesList";
import SaleDetails from "../../pages/dashboard/sales/salesList/SaleDetails";

const SalesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SalesIndex />}>
        <Route path="/" element={<SalesList />} />
        <Route path="/add-sale" element={<AddSales />} />
        <Route path="/sale-detail" element={<SaleDetails />} />
      </Route>
    </Routes>
  );
};

export default SalesRoutes;
