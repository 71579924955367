import React from "react";
import { Route, Routes } from "react-router-dom";
import ReportsIndex from "../../pages/dashboard/reports/ReportsIndex";
import SalesReports from "../../pages/dashboard/reports/salesReports/SalesReports";
import PurchasesReports from "../../pages/dashboard/reports/purchasesReports/PurchasesReports";
import ReturnReports from "../../pages/dashboard/reports/returnedReports/ReturnedReports";
import InventoryReports from "../../pages/dashboard/reports/inventoryReports/InventoryReports";
import LoanReports from "../../pages/dashboard/reports/loanReports/LoanReports";
import GeneralReports from "../../pages/dashboard/reports/generalReports/GeneralReports";

const ReportsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ReportsIndex />}>
        <Route path="/" element={<SalesReports />} />
        <Route path="/sales" element={<SalesReports />} />
        <Route path="/purchases" element={<PurchasesReports />} />
        <Route path="/returned" element={<ReturnReports />} />
        <Route path="/inventory" element={<InventoryReports />} />
        <Route path="/loan" element={<LoanReports />} />
        <Route path="/general" element={<GeneralReports />} />
      </Route>
    </Routes>
  );
};

export default ReportsRoutes;
