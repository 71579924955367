import React from "react";
import { Alert, Grid, Typography, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";

const NoRecordMessage = ({ entityName = "records" }) => {
  const {t}= useTranslation();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ borderTop: "4px solid #4caf50", minHeight: "200px" }}
    >
      <Alert
        severity="info"
        icon={<InfoIcon fontSize="large" />}
        sx={{
          padding: "10px",
          width: "100%",
          backgroundColor: "#f0f4c3",
          border: "1px solid #cddc39",
          borderRadius: "8px",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography
            variant="h4"
            color="textPrimary"
            fontWeight="bold"
            gutterBottom
          >
             {t("product.no")} {entityName} {t("product.productRecord")} 
          </Typography>
          <Typography variant="body1" color="textSecondary">
          {t("product.notfound")}{entityName} {t("product.notfound1")}
          </Typography>
        </Box>
      </Alert>
    </Grid>
  );
};

export default NoRecordMessage;
