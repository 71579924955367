import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  Chip,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "react-bootstrap"; // Import Bootstrap Table
import ErrorPage from "../../../../components/errorPage/ErrorPage";
import { useAuth } from "../../../../context/AuthContext";
import NoRecordMessage from "../../../../components/noRecordMessage/NoRecordMessage";
import TableSkeletonLoading from "../../../../components/tableSkeletonLoading/TableSkeletonLoading";
import { useNavigate } from "react-router-dom";
import useDelete from "../../../../api/useDelete";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import PaymentPastLoan from "./PaymentPastLoan";

const PastLoanList = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [openDialog, setOpenDialog] = useState(null);
  const [loanStatusFilter, setLoanStatusFilter] = useState(""); // State for paid/unpaid filtering
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const [keyword, setKeyword] = useState("");

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["PastLoanList"],
    `past-loan/?search=${keyword}&loan_status=${loanStatusFilter}`, // Updated query
    token
  );

  const { handleDelete, DeleteMessageComponent, ConfirmDialog } = useDelete(
    `past-loan`,
    token
  );

  useEffect(() => {
    refetch();
  }, [refetch, keyword, loanStatusFilter]); // Added filterDate to dependencies

  if (isLoading) {
    return <TableSkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid
        item
        xs={12}
        md={11.5}
        padding={"15px"}
        bgcolor={"white"}
        borderRadius={"5px"}
      >
        <Typography variant="h6" fontWeight={"bold"} gutterBottom>
          Past Loan List
        </Typography>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item xs={12} md={5} mb={1}>
            <TextField
              fullWidth
              type="search"
              size="small"
              placeholder={t("purchase.search")}
              name="keyword"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              sx={{
                bgcolor: "#e6e6eb",
                borderRadius: "4px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} textAlign={"end"}>
            <FormControl size="small" sx={{ width: 150, marginLeft: 2 }}>
              <InputLabel>Creditor</InputLabel>
              <Select
                value={loanStatusFilter}
                label="Creditor"
                onChange={(e) => setLoanStatusFilter(e.target.value)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value={1}>We</MenuItem>
                <MenuItem value={2}>They</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mb={1}>
          <DeleteMessageComponent />
        </Grid>

        {data?.length === 0 ? (
          <NoRecordMessage entityName={t("loan.loan")} />
        ) : (
          <div className="table-responsive">
            <Table bordered hover className="table table-striped table-sm">
              <thead className="table-dark">
                <tr>
                  <th>{t("purchase.num")}</th>
                  <th>{t("loan.customer")}</th>
                  <th>Creditor</th>
                  <th>Status</th>
                  <th>{t("loan.loanAmount")}</th>
                  <th>Repayment</th>
                  <th>{t("loan.loanDate")}</th>
                  <th>Return</th>
                  <th>{t("purchase.action")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>
                      {item.customer_display?.firstname}{" "}
                      {item.customer_display?.lastname}
                    </td>
                    <td>
                      {item.loan_status === 1 && "We"}
                      {item.loan_status === 2 && "They"}
                    </td>

                    <td>
                      <Chip
                        label={item.is_paid ? "Paid" : "Unpaid"}
                        color={item.is_paid ? "success" : "error"}
                        variant="outlined"
                        sx={{ width: { xs: "20vw", sm: "15vw", md: "7vw" } }}
                      />
                    </td>
                    <td>
                      {item.loan_amount} {item.currency === "AFG" && "؋"}{" "}
                      {item.currency === "USD" && "$"}{" "}
                      {item.currency === "PKR" && "PKR"}{" "}
                    </td>

                    <td>
                      {item.repayment_amount} {item.currency === "AFG" && "؋"}{" "}
                      {item.currency === "USD" && "$"}{" "}
                      {item.currency === "PKR" && "PKR"}{" "}
                    </td>
                    <td>
                      {item.loan_date.slice(0, 10)}{" "}
                      {item.loan_date.slice(11, 16)}
                    </td>
                    <td>
                      <KeyboardReturnIcon
                        sx={{ color: "blue", cursor: "pointer" }}
                        fontSize="large"
                        onClick={() => {
                          setValue(item);
                          handleOpenDialog();
                        }}
                      />
                    </td>
                    <td>
                      <EditIcon
                        sx={{ color: "blue", cursor: "pointer", mr: 1 }}
                        onClick={() => {
                          navigate("/past-loan/edit-past-loan", {
                            state: { item },
                          });
                        }}
                      />
                      <DeleteIcon
                        sx={{ color: "#f09690", cursor: "pointer" }}
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <ConfirmDialog />
      </Grid>

      <Dialog open={openDialog} onClose={handleOpenDialog}>
        <DialogTitle>Payment Past Loan</DialogTitle>
        <PaymentPastLoan data={value} onClose={handleOpenDialog} />
      </Dialog>
    </Grid>
  );
};

export default PastLoanList;
