import React from "react";
import { Route, Routes } from "react-router-dom";
import ProductIndex from "../../pages/dashboard/product/ProductIndex";
import ProductList from "../../pages/dashboard/product/productList/ProductList";
import AddProduct from "../../pages/dashboard/product/addProduct/AddProduct";
import EditProduct from "../../pages/dashboard/product/editProduct/EditProduct";

const ProductRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProductIndex />}>
        <Route path="/" element={<ProductList />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/edit-product" element={<EditProduct />} />
      </Route>
    </Routes>
  );
};

export default ProductRoutes;
