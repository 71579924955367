import React from "react";
import { Route, Routes } from "react-router-dom";
import SupplierIndex from "../../pages/dashboard/supplier/SupplierIndex";
import SupplierList from "../../pages/dashboard/supplier/supplierList/SupplierList";
import AddSupplier from "../../pages/dashboard/supplier/addSupplier/AddSupplier";
import EditSupplier from "../../pages/dashboard/supplier/editSupplier/EditSupplier";

const SupplierRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SupplierIndex />}>
        <Route path="/" element={<SupplierList />} />
        <Route path="/add-supplier" element={<AddSupplier />} />
        <Route path="/edit-supplier" element={<EditSupplier />} />
      </Route>
    </Routes>
  );
};

export default SupplierRoutes;
