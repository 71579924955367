import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import useAdd from "../../../../api/useAdd";
import { useTranslation } from "react-i18next";

const SignIn = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const navigate = useNavigate();

  const { handleAdd, resetForm, FormMessageComponent, responseData, loading } =
    useAdd("login");

  useEffect(() => {
    if (resetForm) {
      auth.login(responseData);
      navigate("/");
    }
  }, [resetForm, auth, navigate, responseData]);

  const handleSignIn = (e) => {
    e.preventDefault();
    let isValid = true;

    if (!username) {
      setUsernameError(t("signin.usernameRequired"));
      isValid = false;
    } else {
      setUsernameError("");
      isValid = true;
    }

    if (!password) {
      setPasswordError(t("signin.passwordRequired"));
      isValid = false;
    } else {
      setPasswordError("");
      isValid = true;
    }

    if (isValid) {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      handleAdd(formData);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "80vh",
        width: "100%",
        mt: 2,
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ width: 1 }}
        component={"form"}
        onSubmit={handleSignIn}
      >
        <Card
          sx={{
            p: 3,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography variant="h4" textAlign={"center"}>
            {t("signin.signIn")}
          </Typography>

          {loading && (
            <Typography textAlign={"center"}>
              <CircularProgress />
            </Typography>
          )}

          <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
            {t("signin.dontHaveAccount")}
            <Link variant="subtitle2" sx={{ ml: 0.5 }}>
              {t("signin.getStarted")}
            </Link>
          </Typography>
          <Grid container>
            <FormMessageComponent />
          </Grid>

          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {t("signin.or")}
            </Typography>
          </Divider>

          <Stack spacing={3} justifyContent="center">
            <TextField
              autoFocus
              type="username"
              name="username"
              label={t("signin.username")}
              error={!!usernameError}
              helperText={usernameError}
              onChange={(e) => setUsername(e.target.value)}
            />

            <TextField
              name="password"
              label={t("signin.password")}
              error={!!passwordError}
              helperText={passwordError}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    ></IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ my: 3 }}
          >
            <Link variant="subtitle2" underline="hover">
              {t("signin.forgotPassword")}
            </Link>
          </Stack>

          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="warning"
            disabled={loading}
          >
            {t("signin.login")}
          </Button>
        </Card>
      </Stack>
    </Box>
  );
};

export default SignIn;
