import React from "react";
import { Grid } from "@mui/material";
import { useNavigate, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ReportsNavBar = () => {
  const {t}=useTranslation();
  const navigate = useNavigate();

  const linkStyle = {
    margin: "0px 20px",
    fontWeight: 500,
    textDecoration: "none",
    color: "inherit",
  };

  const activeStyle = {
    backgroundColor: "#c3e7eb",
    padding: "7px ",
    borderRadius: "5px",
  };

  return (
    <Grid
      container
      bgcolor={"white"}
      padding={"10px"}
      alignItems="center"
      spacing={2}
    >
      <Grid
        item
        xs={12}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {/* Uncomment this button if needed */}
        {/* <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            navigate("/loans/add-loan");
          }}
        >
          New
        </Button> */}

        <NavLink
          to="/reports/sales"
          style={({ isActive }) =>
            isActive ? { ...linkStyle, ...activeStyle } : linkStyle
          }
        >
         
          {t("report.saleReport")}
        </NavLink>

        <NavLink
          to="/reports/purchases"
          style={({ isActive }) =>
            isActive ? { ...linkStyle, ...activeStyle } : linkStyle
          }
        >
         
          {t("report.purchaseReport")}
        </NavLink>

        <NavLink
          to="/reports/returned"
          style={({ isActive }) =>
            isActive ? { ...linkStyle, ...activeStyle } : linkStyle
          }
        >
         
          {t("report.returnedeReport")}
        </NavLink>

        <NavLink
          to="/reports/inventory"
          style={({ isActive }) =>
            isActive ? { ...linkStyle, ...activeStyle } : linkStyle
          }
        >
           {t("report.inventoryReport")}
          
        </NavLink>

        <NavLink
          to="/reports/loan"
          style={({ isActive }) =>
            isActive ? { ...linkStyle, ...activeStyle } : linkStyle
          }
        >
          {t("report.loanReport")}
       
        </NavLink>

        <NavLink
          to="/reports/general"
          style={({ isActive }) =>
            isActive ? { ...linkStyle, ...activeStyle } : linkStyle
          }
        >
         
          {t("report.genralReport")}
        </NavLink>
      </Grid>
    </Grid>
  );
};

export default ReportsNavBar;
