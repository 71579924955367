import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context/AuthContext";

const PaymentPastLoan = ({ data, onClose }) => {
  const theme = useTheme();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [formData, setFormData] = useState({
    amount: "",
    payment_date: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const { handleAdd, resetForm, setResetForm, FormMessageComponent, loading } =
    useAdd("past-loan-payment", token); // Adjust endpoint for loan payment

  useEffect(() => {
    if (resetForm) {
      setFormData({
        amount: "",
        payment_date: "",
      });
      setFormErrors({});
    }
    setResetForm(false);
  }, [resetForm, setResetForm]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const remainingBalance = data?.loan_amount - data?.repayment_amount;

    // Validation: check if amount is empty or greater than remaining balance
    if (!formData.amount) {
      setFormErrors({ amount: "Repayment amount is required" });
      return;
    }

    if (!formData.payment_date) {
      setFormErrors({ payment_date: "payment data  is required" });
      return;
    }

    if (parseFloat(formData.amount) > remainingBalance) {
      setFormErrors({
        amount: `Repayment amount cannot be greater than the remaining balance of ${remainingBalance} ${data?.currency}`,
      });
      return;
    }

    // If validation passes, submit the data
    const postData = {
      loan: data?.id,
      amount: formData?.amount,
      payment_date: formData?.payment_date,
    };
    handleAdd(postData);
  };

  return (
    <>
      <DialogContent
        dividers
        sx={{ bgcolor: theme.palette.background.default }}
      >
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <FormMessageComponent />

            {/* Display customer name */}
            <Typography variant="h6" gutterBottom>
              Customer:{" "}
              {`${data?.customer_display?.firstname} ${data?.customer_display?.lastname}`}
            </Typography>

            {/* Loan Amount Information */}
            <Box
              mt={2}
              p={2}
              bgcolor={theme.palette.grey[200]}
              borderRadius={1}
            >
              <Typography variant="subtitle1">
                Total Loan Amount:{" "}
                <strong>
                  {data?.loan_amount} {data?.currency}
                </strong>
              </Typography>
              <Typography variant="subtitle1">
                Amount Repaid:{" "}
                <strong>
                  {data?.repayment_amount} {data?.currency}
                </strong>
              </Typography>
              <Typography variant="subtitle1">
                Remaining Balance:{" "}
                <strong>
                  {data?.loan_amount - data?.repayment_amount} {data?.currency}
                </strong>
              </Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                {/* Creditor Field */}
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Loan Creditor"
                  value={data?.loan_status === 1 ? "We" : "They"}
                  disabled
                  margin="normal"
                />
              </Grid>

              <Grid item xs={6}>
                {/* Currency Field */}
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Currency"
                  value={data?.currency}
                  disabled
                  margin="normal"
                />
              </Grid>
            </Grid>

            {/* Repayment Loan Field */}
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              label="Repayment Loan Amount"
              name="amount"
              value={formData.amount}
              error={!!formErrors.amount}
              helperText={formErrors.amount}
              onChange={(e) => {
                setFormData({ ...formData, amount: e.target.value });
                setFormErrors({ ...formErrors, amount: "" }); // Clear errors on change
              }}
              margin="normal"
            />

            {/* Loan Date Field */}
            <TextField
              fullWidth
              type="datetime-local"
              variant="outlined"
              label="Loan Date"
              name="payment_date"
              value={formData.payment_date}
              error={!!formErrors.payment_date}
              helperText={formErrors.payment_date}
              onChange={(e) =>
                setFormData({ ...formData, payment_date: e.target.value })
              }
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="secondary"
          variant="outlined"
          sx={{ textTransform: "capitalize" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          sx={{ textTransform: "capitalize" }}
        >
          {loading ? "Submitting..." : "Submit Payment"}
        </Button>
      </DialogActions>
    </>
  );
};

export default PaymentPastLoan;
