import { Grid } from "@mui/material";
import React from "react";

import { Outlet } from "react-router-dom";
import InventoryNavBar from "./customerNavBar/CustomerNavBar";

const CustomerIndex = () => {
  return (
    <Grid container>
      <InventoryNavBar />
      <Outlet />
    </Grid>
  );
};

export default CustomerIndex;
