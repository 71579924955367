import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import App from "../App";
import Home from "../layouts/home/Home";
import InventoryRoutes from "./inventoryRoutes/InventoryRoutes";
import AuthRoutes from "./authRoutes/AuthRoutes";
import CustomerRoutes from "./customerRoutes/CustomerRoutes";
import SupplierRoutes from "./supplierRoutes/SupplierRoutes";
import PurchaseRoutes from "./purchaseRoutes/PurchaseRoutes";
import SalesRoutes from "./salesRoutes/SalesRoutes";
import ProductRoutes from "./productRoutes/ProductRoutes";
import LoanRoutes from "./loanRoutes/LoanRoutes";
import ExpenseRoutes from "./expenseRoutes/ExpenseRoutes";
import ReportsRoutes from "./reportsRoutes/ReportsRoutes";
import { useAuth } from "../context/AuthContext";
import PastLoanRoutes from "./pastLoanRoutes/PastLoanRoutes";

const IndexRouter = () => {
  const auth = useAuth();
  const user = auth?.user;
  return (
    <Routes>
      <Route
        path="/"
        element={user ? <App /> : <Navigate to={"/auth/login/"} />}
      >
        <Route path="/" element={<Home />} />
        <Route path="/inventory/*" element={<InventoryRoutes />} />
        <Route path="/customers/*" element={<CustomerRoutes />} />
        <Route path="/suppliers/*" element={<SupplierRoutes />} />
        <Route path="/purchases/*" element={<PurchaseRoutes />} />
        <Route path="/sales/*" element={<SalesRoutes />} />
        <Route path="/products/*" element={<ProductRoutes />} />
        <Route path="/loans/*" element={<LoanRoutes />} />
        <Route path="/expense/*" element={<ExpenseRoutes />} />
        <Route path="/reports/*" element={<ReportsRoutes />} />
        <Route path="/past-loan/*" element={<PastLoanRoutes />} />
      </Route>
      <Route path="/auth/*" element={<AuthRoutes />} />
    </Routes>
  );
};

export default IndexRouter;
