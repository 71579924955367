import React, { useEffect, useState } from "react";
import { Box, TextField, MenuItem, Typography, Grid } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"; // Changed icon for better representation of expense
import useUpdate from "../../../../api/useUpdate";
import { useAuth } from "../../../../context/AuthContext";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditExpense = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const location = useLocation();

  // Use optional chaining and default to an empty object
  const { item } = location.state || { item: {} };

  // Default form data structure to prevent undefined values
  const [formData, setFormData] = useState({
    expense_by: item?.expense_by || "", // Field for who incurred the expense
    expense_amount: item?.expense_amount || "", // Field for the amount of the expense
    expense_date: item?.expense_date || "", // Field for the date of the expense
    expense_on: item?.expense_on || "", // New field for expense details
    currency: item?.currency || "", // New field for expense details
  });

  const [formErrors, setFormErrors] = useState({});

  const { handleUpdate, resetForm, Buttons, FormMessageComponent } = useUpdate(
    "expenses",
    token
  );

  useEffect(() => {
    if (resetForm) {
      setFormData({
        expense_by: "",
        expense_amount: "",
        expense_date: "",
        expense_on: "",
        currency: "",
      });
      setFormErrors({});
    }
  }, [resetForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    // Validation
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) errors[key] = t("expense.expenseField");
    });

    if (Object.keys(errors).length === 0) {
      const expenseData = new FormData();
      Object.keys(formData).forEach((key) =>
        expenseData.append(key, formData[key])
      );
      handleUpdate(item?.id, expenseData);
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  // Check if the item is loaded
  if (!item) {
    return <Typography variant="h6">{t("expense.lodingExpense")}</Typography>;
  }

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid item xs={11} md={6}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            maxWidth: "600px",
            width: "100%",
            margin: "auto",
            padding: "40px",
            boxShadow: 4,
            borderRadius: "12px",
            background: "white",
            transition: "all 0.3s ease-in-out",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <MonetizationOnIcon
              sx={{ fontSize: "50px", color: "primary.main" }}
            />
            <Typography
              variant="h4"
              component="h1"
              sx={{ marginLeft: "10px", fontWeight: "bold" }}
            >
              {t("expense.editExpense")}
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <FormMessageComponent />
          </Box>

          {/* Expense By */}
          <TextField
            fullWidth
            type="text"
            variant="standard"
            label={t("expense.expenseBy")}
            name="expense_by"
            value={formData.expense_by}
            error={!!formErrors.expense_by}
            helperText={formErrors.expense_by}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
          />

          {/* Expense Amount */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7.5}>
              <TextField
                fullWidth
                type="number"
                variant="standard"
                label={t("expense.expenseAmount")}
                name="expense_amount"
                value={formData.expense_amount}
                error={!!formErrors.expense_amount}
                helperText={formErrors.expense_amount}
                onChange={handleChange}
                sx={{ marginBottom: "20px" }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                fullWidth
                variant="standard"
                label={t("expense.currency")}
                name="currency"
                value={formData.currency}
                onChange={handleChange}
              >
                <MenuItem value="AFG">{t("purchase.afg")}</MenuItem>
                <MenuItem value="USD">{t("purchase.usd")}</MenuItem>
                <MenuItem value="PKR">{t("purchase.pkr")}</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          {/* Expense Date */}
          <TextField
            fullWidth
            type="date"
            variant="standard"
            label={t("expense.expenseDate")}
            name="expense_date"
            value={formData.expense_date}
            error={!!formErrors.expense_date}
            helperText={formErrors.expense_date}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          {/* Expense On */}
          <TextField
            fullWidth
            type="text"
            variant="standard"
            label={t("expense.expenseOn")}
            name="expense_on"
            value={formData.expense_on}
            error={!!formErrors.expense_on}
            helperText={formErrors.expense_on}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
          />

          <Buttons onSubmit={handleSubmit} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditExpense;
