// components/dashboard/students/StudentRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import InventoryIndex from "../../pages/dashboard/inventory/InventoryIndex";
import InventoryList from "../../pages/dashboard/inventory/inventoryList/InventoryList";

const InventoryRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<InventoryIndex />}>
        <Route path="/" element={<InventoryList />} />
      </Route>
    </Routes>
  );
};

export default InventoryRoutes;
