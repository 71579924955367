// src/api/useDelete.js
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Stack,
  Typography,
  IconButton,
  
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";


const useDelete = (queryKey, token) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [deleteMessage, setDeleteMessage] = useState("");
  const [deleteMessageOpen, setDeleteMessageOpen] = useState(false);
  const [deleteMessageSeverity, setDeleteMessageSeverity] = useState("success");

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const deleteMutation = useMutation({
    mutationFn: async (id) => {
      try {
        const headers = token ? { Authorization: `Token ${token}` } : {};
        await axios.delete(`${process.env.REACT_APP_API_URL}/${queryKey}/${id}/`, {
          headers,
        });
      } catch (error) {
        throw new Error(
          error.response?.data?.detail || t("usedelete.deletefeild")
        );
      }
    },
    onSuccess: () => {
      setDeleteMessage(t("usedelete.deletesuccec"));
      setDeleteMessageSeverity('success');
      setDeleteMessageOpen(true);
      queryClient.invalidateQueries(queryKey);
    },
    onError: (error) => {
      setDeleteMessage(error.message);
      setDeleteMessageSeverity("error");
      setDeleteMessageOpen(true);
    },
  });

  const handleDelete = (id) => {
    setItemToDelete(id);
    setConfirmDialogOpen(true);
  };

  const confirmDelete = () => {
    deleteMutation.mutate(itemToDelete);
    setConfirmDialogOpen(false);
  };

  useEffect(() => {
    if (deleteMessageOpen) {
      const timer = setTimeout(() => {
        setDeleteMessageOpen(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [deleteMessageOpen]);

  const DeleteMessageComponent = () => (
    <Box
      sx={{
        display: deleteMessageOpen ? "flex" : "none",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: deleteMessageSeverity === "success" ? "green" : "red",
        color: "#fff",
        padding: "10px 15px",
        borderRadius: "4px",
        mt: 2,
        position: "relative",
        width: "100%",
      }}
    >
      <Box>{deleteMessage}</Box>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setDeleteMessageOpen(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );

  const ConfirmDialog = () => (
    <Dialog
      open={confirmDialogOpen}
      onClose={() => setConfirmDialogOpen(false)}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
          }}
        >
          <WarningAmberIcon color="error" sx={{ fontSize: "5rem" }} />
          <Typography variant="h5" fontWeight={"bold"}>
            
            {t("usedelete.confiremDeleting")}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
      {t("usedelete.deletingMessage")}
      </DialogContent>
      <DialogActions>
        
        <Button
          variant="contained"
          onClick={() => setConfirmDialogOpen(false)}
          color="primary"
        >
         {t("usedelete.cancel")}
        </Button>
        <Button variant="contained" onClick={confirmDelete} color="error" style={{margin:"0em 1em"}} >
        {t("usedelete.delete")}
        </Button>
        
      </DialogActions>
    </Dialog>
  );

  return { handleDelete, DeleteMessageComponent, ConfirmDialog };
};

export default useDelete;
