import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  Autocomplete,
  MenuItem,
  Button,
} from "@mui/material";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context/AuthContext";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AddPurchase = ({ md = 6 }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const navigate = useNavigate();

  // Form state
  const [formData, setFormData] = useState({
    product: null, // Change to object
    supplier: null, // Change to object
    purchase_quantity: "",
    unit_price: "",
    retail_sale_price_in_afg: 0,
    retail_sale_price_in_usd: 0,
    retail_sale_price_in_pkr: 0,
    whole_sale_price_in_afg: 0,
    whole_sale_price_in_usd: 0,
    whole_sale_price_in_pkr: 0,
    description: "",
    currency: "AFG", // Default currency
  });

  const [formErrors, setFormErrors] = useState({});

  // Custom hook for handling form submission
  const { handleAdd, resetForm, Buttons, setResetForm, FormMessageComponent } =
    useAdd("purchases", token);

  // Fetch products
  const {
    data: products,
    isLoading: isLoadingProducts,
    isError: isErrorProducts,
  } = useFetchObjects(["products"], "products/", token);

  // Fetch suppliers
  const {
    data: suppliers,
    isLoading: isLoadingSuppliers,
    isError: isErrorSuppliers,
  } = useFetchObjects(["suppliers"], "suppliers/", token);

  // Reset form after successful submission
  useEffect(() => {
    if (resetForm) {
      setFormData({
        product: null,
        supplier: null,
        purchase_quantity: "",
        unit_price: "",
        retail_sale_price_in_afg: "",
        retail_sale_price_in_usd: "",
        retail_sale_price_in_pkr: "",
        whole_sale_price_in_afg: "",
        whole_sale_price_in_usd: "",
        whole_sale_price_in_pkr: "",
        description: "",
        currency: "", // Default currency
      });
      setFormErrors({});
    }
  }, [resetForm]);

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    // Validation
    Object.keys(formData).forEach((key) => {
      if (!formData[key] && key !== "currency" && key !== "description")
        errors[key] = t("purchase.fieldRequired");
    });

    if (Object.keys(errors).length === 0) {
      // Submit only IDs for foreign key fields
      const submitData = {
        ...formData,
        product: formData.product?.id,
        supplier: formData.supplier?.id,
      };
      handleAdd(submitData); // Pass the form data with IDs
    } else {
      setFormErrors(errors);
    }
  };

  // Input change handler
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid item xs={11} md={md}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            maxWidth: "600px",
            width: "100%",
            margin: "auto",
            padding: "40px",
            boxShadow: 4,
            borderRadius: "12px",
            background: "white",
            transition: "all 0.3s ease-in-out",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <ProductionQuantityLimitsIcon
              sx={{ fontSize: "50px", color: "primary.main" }}
            />
            <Typography
              variant="h4"
              component="h1"
              sx={{ marginLeft: "10px", fontWeight: "bold" }}
            >
              {t("purchase.addPurchase")}
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <FormMessageComponent />
          </Box>

          <Grid container spacing={2}>
            {/* Product Autocomplete */}
            <Grid item xs={12} sm={6}>
              {isLoadingProducts ? (
                <CircularProgress />
              ) : isErrorProducts ? (
                <Typography color="error">
                  {t("purchase.errorLoding")}
                </Typography>
              ) : (
                <Autocomplete
                  options={Array.isArray(products) ? products : []} // Ensure options is an array
                  getOptionLabel={(option) => option.name || ""}
                  value={formData.product || null}
                  onChange={(e, newValue) => {
                    setFormData({ ...formData, product: newValue });
                    setFormErrors({ ...formErrors, product: "" });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("purchase.product")}
                      error={!!formErrors.product}
                      helperText={formErrors.product}
                    />
                  )}
                />
              )}
            </Grid>
            {/* Purchase Quantity */}
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                sx={{ padding: "15px" }}
                variant="contained"
                onClick={() => {
                  navigate("/products/add-product");
                }}
              >
                {t("purchase.addProduct")}
              </Button>
            </Grid>

            {/* Supplier Autocomplete */}
            <Grid item xs={12} sm={6}>
              {isLoadingSuppliers ? (
                <CircularProgress />
              ) : isErrorSuppliers ? (
                <Typography color="error">
                  {t("purchase.errorSuppli")}
                </Typography>
              ) : (
                <Autocomplete
                  options={Array.isArray(suppliers) ? suppliers : []} // Ensure options is an array
                  getOptionLabel={(option) =>
                    `${option.firstname} ${option.lastname}` || ""
                  }
                  value={formData.supplier || null}
                  onChange={(e, newValue) => {
                    setFormData({ ...formData, supplier: newValue });
                    setFormErrors({ ...formErrors, supplier: "" });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("purchase.supplier")}
                      error={!!formErrors.supplier}
                      helperText={formErrors.supplier}
                    />
                  )}
                />
              )}
            </Grid>

            {/* Purchase Quantity */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label={t("purchase.purchaseQuantity")}
                name="purchase_quantity"
                value={formData.purchase_quantity}
                error={!!formErrors.purchase_quantity}
                helperText={formErrors.purchase_quantity}
                onChange={handleChange}
              />
            </Grid>

            {/* Unit Price */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label={t("purchase.unitPrice")}
                name="unit_price"
                value={formData.unit_price}
                error={!!formErrors.unit_price}
                helperText={formErrors.unit_price}
                onChange={handleChange}
              />
            </Grid>

            {/* Currency */}
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label={t("purchase.currency")}
                name="currency"
                value={formData.currency}
                onChange={handleChange}
              >
                <MenuItem value="AFG">{t("purchase.afg")}</MenuItem>
                <MenuItem value="USD">{t("purchase.usd")}</MenuItem>
                <MenuItem value="PKR">{t("purchase.pkr")}</MenuItem>
              </TextField>
            </Grid>

            {/* Retail Sale Price in AFN */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label={t("purchase.retailSalePrice") + t("inventory.afg")}
                name="retail_sale_price_in_afg"
                value={formData.retail_sale_price_in_afg}
                error={!!formErrors.retail_sale_price_in_afg}
                helperText={formErrors.retail_sale_price_in_afg}
                onChange={handleChange}
              />
            </Grid>

            {/* Retail Sale Price in USD */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label={t("purchase.retailSalePrice") + t("inventory.usd")}
                name="retail_sale_price_in_usd"
                value={formData.retail_sale_price_in_usd}
                error={!!formErrors.retail_sale_price_in_usd}
                helperText={formErrors.retail_sale_price_in_usd}
                onChange={handleChange}
              />
            </Grid>

            {/* Retail Sale Price in PKR */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label={t("purchase.retailSalePrice") + t("inventory.pkr")}
                name="retail_sale_price_in_pkr"
                value={formData.retail_sale_price_in_pkr}
                error={!!formErrors.retail_sale_price_in_pkr}
                helperText={formErrors.retail_sale_price_in_pkr}
                onChange={handleChange}
              />
            </Grid>

            {/* Wholesale Sale Price in AFN */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label={t("purchase.wholesalePrice") + t("inventory.afg")}
                name="whole_sale_price_in_afg"
                value={formData.whole_sale_price_in_afg}
                error={!!formErrors.whole_sale_price_in_afg}
                helperText={formErrors.whole_sale_price_in_afg}
                onChange={handleChange}
              />
            </Grid>

            {/* Wholesale Sale Price in USD */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label={t("purchase.wholesalePrice") + t("inventory.usd")}
                name="whole_sale_price_in_usd"
                value={formData.whole_sale_price_in_usd}
                error={!!formErrors.whole_sale_price_in_usd}
                helperText={formErrors.whole_sale_price_in_usd}
                onChange={handleChange}
              />
            </Grid>

            {/* Wholesale Sale Price in PKR */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label={t("purchase.wholesalePrice") + t("inventory.pkr")}
                name="whole_sale_price_in_pkr"
                value={formData.whole_sale_price_in_pkr}
                error={!!formErrors.whole_sale_price_in_pkr}
                helperText={formErrors.whole_sale_price_in_pkr}
                onChange={handleChange}
              />
            </Grid>

            {/* Wholesale Sale Price in PKR */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={formData.description}
                error={!!formErrors.description}
                helperText={formErrors.description}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>

          <Box marginTop={3}>
            <Buttons onSubmit={handleSubmit} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddPurchase;
