import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Grid, MenuItem } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context/AuthContext";
import { useTranslation } from "react-i18next";

const AddExpense = () => {
  const {t}=useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [formData, setFormData] = useState({
    expense_by: "", // Field for who incurred the expense
    expense_on: "", // Field for who incurred the expense
    expense_amount: "", // Field for the amount of the expense
    expense_date: "", // Field for the date of the expense
    currency: "AFG",
  });

  const [formErrors, setFormErrors] = useState({});

  const { handleAdd, resetForm, Buttons, setResetForm, FormMessageComponent } =
    useAdd("expenses", token); // Adjust endpoint for expenses

  useEffect(() => {
    if (resetForm) {
      setFormData({
        expense_by: "",
        expense_on: "",
        expense_amount: "",
        expense_date: "",
        currency: "",
      });
      setFormErrors({});
    }
    setResetForm(false);
  }, [resetForm, setResetForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    // Validation
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) errors[key] = t("expense.expenseField");
    });

    if (Object.keys(errors).length === 0) {
      const expenseData = new FormData();
      Object.keys(formData).forEach((key) =>
        expenseData.append(key, formData[key])
      );
      handleAdd(expenseData);
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid item xs={11} md={6}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            maxWidth: "600px",
            width: "100%",
            margin: "auto",
            padding: "40px",
            boxShadow: 4,
            borderRadius: "12px",
            background: "white",
            transition: "all 0.3s ease-in-out",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <MonetizationOnIcon
              sx={{ fontSize: "50px", color: "primary.main" }}
            />
            <Typography
              variant="h4"
              component="h1"
              sx={{ marginLeft: "10px", fontWeight: "bold" }}
            >
             {t("expense.addExpense")}
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <FormMessageComponent />
          </Box>

          {/* Expense By */}
          <TextField
            fullWidth
            type="text"
            variant="standard"
            label= {t("expense.expenseBy")}
            name="expense_by"
            value={formData.expense_by}
            error={!!formErrors.expense_by}
            helperText={formErrors.expense_by}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
          />

          {/* Expense on */}
          <TextField
            fullWidth
            type="text"
            variant="standard"
            label={t("expense.expenseOn")}
            name="expense_on"
            value={formData.expense_on}
            error={!!formErrors.expense_on}
            helperText={formErrors.expense_on}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
          />

          {/* Expense Amount */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7.5}>
              <TextField
                fullWidth
                type="number"
                variant="standard"
                label={t("expense.expenseAmount")}
                name="expense_amount"
                value={formData.expense_amount}
                error={!!formErrors.expense_amount}
                helperText={formErrors.expense_amount}
                onChange={handleChange}
                sx={{ marginBottom: "20px" }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                fullWidth
                variant="standard"
                label={t("expense.currency")}
                name="currency"
                value={formData.currency}
                onChange={handleChange}
              >
                <MenuItem value="AFG">{t("purchase.afg")}</MenuItem>
                <MenuItem value="USD">{t("purchase.usd")}</MenuItem>
                <MenuItem value="PKR">{t("purchase.pkr")}</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          {/* Expense Date */}
          <TextField
            fullWidth
            type="date"
            variant="standard"
            label={t("expense.expenseDate")}
            name="expense_date"
            value={formData.expense_date}
            error={!!formErrors.expense_date}
            helperText={formErrors.expense_date}
            onChange={handleChange}
            sx={{ marginBottom: "20px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Buttons onSubmit={handleSubmit} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddExpense;
