import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ExpenseNavBar = () => {
  const {t}=useTranslation();
  const navigate = useNavigate();
  return (
    <Grid
      container
      bgcolor={"white"}
      padding={"10px"}
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12} md={3} display={"flex"} alignItems={"center"}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon style={{margin:"0em 1em"}}/>}
          onClick={() => {
            navigate("/expense/add-expense");
          }}
        >
          {t("purchase.new")}
        </Button>
        <Typography
          margin={"0px 10px"}
          sx={{
            cursor: "pointer",
            fontWeight: 500,
            ":hover": { textDecoration: "underline", color: "blue" },
          }}
          onClick={() => {
            navigate("/expense");
          }}
        >
           {t("expense.expenseList")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ExpenseNavBar;
