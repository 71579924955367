import React from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const InventoryNavBar = () => {
  const {t}=useTranslation();
  const navigate = useNavigate();
  return (
    <Grid
      container
      bgcolor={"white"}
      padding={"10px"}
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12} display={"flex"} alignItems={"center"}>
        <Button
          disabled
          variant="contained"
          color="primary"
          startIcon={<AddIcon style={{margin:"0em 1em"}} />}
          onClick={() => {
            navigate("/inventory/add-inventory");
          }}
        >
           {t("purchase.new")}
        </Button>
        <Typography
          margin={"0px 10px"}
          sx={{ cursor: "pointer", fontWeight: 500 }}
          onClick={() => {
            navigate("/inventory");
          }}
        >
          {t("inventory.inventoryList")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default InventoryNavBar;
