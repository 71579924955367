import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
// Icons
import ListAltIcon from "@mui/icons-material/ListAlt";
import {
  Avatar,
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useAuth } from "../../context/AuthContext";

import LanguageSelector from "../../lang/LanguageSelector";

function NavBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const open = Boolean(anchorEl);
  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/auth/login");
  };

  return (
    <AppBar
      position="sticky"
      top="0px"
      sx={{
        zIndex: 4,
        bgcolor: "#1f2021",
        boxShadow: "0px 0px 0px",
        // padding: "2px 0px ",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item xs={8} md={2.5} display={"flex"} alignItems={"center"}>
              <Box
                onClick={() => {
                  navigate("/");
                }}
              >
                <ListAltIcon sx={{ fontSize: "3rem" }} />
              </Box>
              <Box
                fontWeight={"bold"}
                ml={1}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <h4 variant="h6" style={{ padding: 0, marginTop: "10px" }}>
                  Inventory-MIS
                </h4>
              </Box>
            </Grid>

            <Grid
              item
              xs={3}
              md={3}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"end"}
            >
              <LanguageSelector />
              {!token ? (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    navigate("/auth/login/");
                  }}
                >
                  Login
                </Button>
              ) : (
                <Box
                  sx={{ textTransform: "capitalize" }}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Typography fontWeight={"bold"} mr={1} ml={1}>
                    {user?.user.username}
                  </Typography>
                  <Avatar onClick={handleOpen} sx={{ cursor: "pointer" }}>
                    {user?.user.username.slice(0, 1)}
                  </Avatar>

                  <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                    {" "}
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleLogout();
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBar;
