import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  Autocomplete,
  Chip,
  MenuItem,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context/AuthContext";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";

const AddPastLoan = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [formData, setFormData] = useState({
    customer: "",
    loan_amount: "",
    currency: "AFG",
    loan_status: "",
    loan_date: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [loanAmount, setLoanAmount] = useState(0); // Store the customer loan_amount
  const [loanData, setLoanData] = useState([]); // Store customer data

  const { handleAdd, resetForm, Buttons, setResetForm, FormMessageComponent } =
    useAdd("past-loan", token); // Adjust endpoint for customer payment

  const {
    data: customerData,
    isLoading: isLoadingLoan,
    isError: isErrorLoan,
  } = useFetchObjects(["customer"], "customers/", token);

  useEffect(() => {
    if (customerData) {
      setLoanData(customerData);
    }
  }, [customerData]);

  useEffect(() => {
    if (resetForm) {
      setFormData({
        customer: "",
        loan_amount: "",
        currency: "AFG",
        loan_date: "",
      });
      setLoanAmount(0); // Reset customer loan_amount
      setFormErrors({});
    }
    setResetForm(false);
  }, [resetForm, setResetForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    // Prepare the data to be sent in the POST request
    const postData = {
      customer: formData.customer.id, // Assuming customer is an object with an ID
      loan_amount: formData.loan_amount,
      currency: formData.currency,
      loan_date: formData.loan_date,
    };

    // Call handleAdd with the postData object (or FormData, depending on how you handle the POST)
    handleAdd(postData);
  };

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    if (formErrors[field]) {
      setFormErrors({ ...formErrors, [field]: "" });
    }

    // Update the customer loan_amount based on the selected customer
    if (field === "customer" && value) {
      setLoanAmount(value.loan_amount); // Set the customer loan_amount
      setFormData({ ...formData, loan_amount: "", customer: value }); // Reset loan_amount field, maintain customer selection
    }
  };

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid item xs={11} md={6}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            maxWidth: "600px",
            width: "100%",
            margin: "auto",
            padding: "40px",
            boxShadow: 4,
            borderRadius: "12px",
            background: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <PersonAddIcon sx={{ fontSize: "50px", color: "primary.main" }} />
            <Typography
              variant="h4"
              component="h1"
              sx={{ marginLeft: "10px", fontWeight: "bold" }}
            >
              Add Past Loan
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <FormMessageComponent />
          </Box>

          {/* Loan Autocomplete */}
          <Autocomplete
            options={customerData || []}
            getOptionLabel={(option) =>
              `${option?.firstname} ${option?.lastname}`
            }
            onChange={(event, value) => handleChange("customer", value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customer"
                variant="standard"
                error={!!formErrors.customer}
                helperText={formErrors.customer}
                fullWidth
                sx={{ marginBottom: "20px" }}
              />
            )}
          />

          {/* Amount Field */}
          <TextField
            fullWidth
            type="number"
            variant="standard"
            label="Loan Amount"
            name="loan_amount"
            value={formData.loan_amount}
            error={!!formErrors.loan_amount}
            helperText={formErrors.loan_amount}
            onChange={(e) => handleChange("loan_amount", e.target.value)}
            inputProps={{
              max: loanAmount, // Set max value based on selected customer
            }}
            sx={{ mt: 3 }}
          />

          {/* Currency Field */}
          <TextField
            select
            fullWidth
            variant="standard"
            label="Currency"
            name="currency"
            value={formData.currency}
            onChange={(e) => handleChange("currency", e.target.value)}
            sx={{ mt: 3 }}
          >
            <MenuItem value="AFG">{t("purchase.afg")}</MenuItem>
            <MenuItem value="USD">{t("purchase.usd")}</MenuItem>
            <MenuItem value="PKR">{t("purchase.pkr")}</MenuItem>
          </TextField>

          {/* Loan Date Field */}
          <TextField
            fullWidth
            type="datetime-local"
            variant="standard"
            label="Loan Date"
            name="loan_date"
            value={formData.loan_date}
            error={!!formErrors.loan_date}
            helperText={formErrors.loan_date}
            onChange={(e) => handleChange("loan_date", e.target.value)}
            sx={{ mt: 3 }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Buttons onSubmit={handleSubmit} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddPastLoan;
