import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  TableContainer,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import { CurrencyExchange as CurrencyIcon } from "@mui/icons-material";
import { Button } from "react-bootstrap";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context/AuthContext";
import ErrorPage from "../../../../components/errorPage/ErrorPage";
import TableSkeletonLoading from "../../../../components/tableSkeletonLoading/TableSkeletonLoading";
import SearchIcon from "@mui/icons-material/Search";
import "./style.css"; // Importing the CSS file
import { useTranslation } from "react-i18next";

const iconStyle = { fontSize: 50, mb: 1, color: "#d32f2f" };

// ReturnReports Component
const ReturnReports = () => {
  const {t}=useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch data from the backend
  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["Reports"],
    `report/returns/?start_date=${startDate}&end_date=${endDate}&search=${searchTerm}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [refetch, searchTerm]);

  // Handle filtering
  const handleFilter = () => {
    refetch();
  };

  if (isLoading) {
    return <TableSkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!data) {
    return <Typography>{t("report.noReturn")}</Typography>;
  }

  const {
    return_totals_by_currency = {},
    product_returns = [],
    customer_returns = [],
  } = data;

  return (
    <Grid container justifyContent={"center"} margin={"20px 0px"}>
      <Grid
        item
        xs={11}
        bgcolor={"white"}
        padding={"10px 20px"}
        borderRadius={"10px"}
      >
        <Grid container>
          <Typography variant="h4" gutterBottom>
            {t("report.returnedeReport")}
          </Typography>

          {/* Date Filters */}
          <Grid container spacing={2} marginBottom={4} mt={1}>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                type="date"
                label={t("report.startDate")}
                variant="outlined"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                type="date"
                label={t("report.endDate")}
                variant="outlined"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                fullWidth
                placeholder={t("report.searchByCP")}
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Button
                  variant="primary"
                  onClick={handleFilter}
                  className="w-100"
                >
                 {t("report.applyFilter")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Return Totals by Currency */}
        <Typography variant="h5" gutterBottom>
          
          {t("report.returnTotalCurrency")}
        </Typography>
        <Grid container spacing={2} marginBottom={4} justifyContent={"center"}>
          {Object.keys(return_totals_by_currency).map((currency) => (
            <Grid item xs={12} md={4} key={currency}>
              <Card className="text-center bg-light p-3">
                <CardContent>
                  <CurrencyIcon className="rotating-icon" sx={iconStyle} />
                  <Typography
                    variant="h5"
                    fontWeight={"bold"}
                    color="textSecondary"
                  >
                    {currency} -  {t("report.totalRA")}

                  </Typography>
                  <Typography variant="h4" fontWeight={"bold"} color="primary">
                    {return_totals_by_currency[
                      currency
                    ]?.total_return_amount?.toLocaleString()}
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight={"bold"}
                    color="textSecondary"
                    marginTop={2}
                  >
                    
                    {t("report.totalRI")}
                  </Typography>
                  <Typography variant="h4" fontWeight={"bold"} color="primary">
                    {return_totals_by_currency[
                      currency
                    ]?.total_returned_items?.toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Product Returns Table */}
        <Typography variant="h5" gutterBottom>
         
          {t("report.productReturn")}
        </Typography>
        <TableContainer elevation={3} className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-dark">
              <tr>
                <th> {t("report.productName")}</th>
                <th> {t("report.saleType")}</th>
                <th> {t("report.currency")}</th>
                <th> {t("report.quanReturn")}</th>
                <th> {t("report.unitPrice")}</th>
                <th> {t("report.totalPrice")}</th>
              </tr>
            </thead>
            <tbody>
              {product_returns.map((returnItem, index) => (
                <tr key={index}>
                  <td>{returnItem.product__name}</td>
                  <td>{returnItem.sale__sales_type}</td>
                  <td>{returnItem.sale__currency}</td>
                  <td>{returnItem.total_quantity}</td>
                  <td> ؋ {returnItem.unit_price?.toLocaleString()}</td>
                  <td> ؋ {returnItem.total_price?.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>

        {/* Customer Returns Table */}
        <Typography variant="h5" gutterBottom className="mt-4">
          
          {t("report.customer")}
        </Typography>
        <TableContainer elevation={3} className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-dark">
              <tr>
                <th>{t("report.customerFname")}</th>
                <th>{t("report.customerLname")}</th>
                <th>{t("report.currency")}</th>
                <th>{t("report.totalReturn")}</th>
              </tr>
            </thead>
            <tbody>
              {customer_returns.map((customer, index) => (
                <tr key={index}>
                  <td>{customer.sale__customer__firstname || "N/A"}</td>
                  <td>{customer.sale__customer__lastname || "N/A"}</td>
                  <td>{customer.sale__currency}</td>
                  <td>{customer.total_returned}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ReturnReports;
