import React, { useState } from "react";
import { Box, Button, TextField, Typography, Grid } from "@mui/material";
import Inventory2Icon from "@mui/icons-material/Inventory2"; // Using a product icon

import { useAuth } from "../../../../context/AuthContext";
import { useLocation } from "react-router-dom";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";

const EditProduct = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const location = useLocation();

  const product = location?.state?.item;

  const [name, setName] = useState(product?.name);
  const [description, setDescription] = useState(product?.description);
  const [error, setError] = useState("");

  const { handleUpdate, Buttons } = useUpdate("products", token);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      setError(t("product.nameRequired"));
      return;
    }

    const customerData = new FormData();
    customerData.append("name", name);
    customerData.append("description", description);
    handleUpdate(product?.id, customerData);
  };

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid item xs={11} md={6}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            maxWidth: "600px",
            width: "100%",
            margin: "auto",
            padding: "40px",
            boxShadow: 4,
            borderRadius: "12px",
            background: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <Inventory2Icon sx={{ fontSize: "50px", color: "primary.main" }} />
            <Typography
              variant="h4"
              component="h1"
              sx={{ marginLeft: "10px", fontWeight: "bold" }}
            >
              {t("product.editProduct")}
            </Typography>
          </Box>

          {/* Name Field */}
          <TextField
            fullWidth
            type="text"
            variant="standard"
            label={t("product.name")}
            value={name}
            error={!!error}
            helperText={error}
            onChange={(e) => {
              setName(e.target.value);
              setError("");
            }}
            sx={{ marginBottom: "20px" }}
          />

          <TextField
            fullWidth
            type="text"
            variant="standard"
            label={t("product.description")}
            name="description"
            value={description}
            multiline
            rows={3}
            error={!!error}
            helperText={error}
            onChange={(e) => {
              setDescription(e.target.value);
              setError("");
            }}
            sx={{ marginBottom: "20px" }}
          />
          <Buttons onSubmit={handleSubmit} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditProduct;
