import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useUpdate from "../../../../api/useUpdate";
import { useAuth } from "../../../../context/AuthContext";
import useFetchObject from "../../../../api/useFetchObject";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useLocation } from "react-router-dom";

const EditPastLoan = () => {
  const auth = useAuth();
  const token = auth?.user?.token;

  const location = useLocation();
  const loan = location?.state?.item; // Assuming loan data is passed via location state

  const { data, isLoading, isError } = useFetchObject(
    ["loan"],
    "past-loan",
    loan?.id,
    token
  ); // Fetch the loan to be edited by ID

  const [formData, setFormData] = useState({
    customer: data?.customer || "",
    loan_amount: data?.loan_amount || "",
    currency: data?.currency || "AFG",
    loan_status: data?.loan_status || "",
    loan_date: data?.loan_date || "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [loanAmount, setLoanAmount] = useState(0); // Store the customer loan_amount
  const [loanData, setLoanData] = useState([]); // Store customer data

  const { handleUpdate, Buttons, FormMessageComponent } = useUpdate(
    "past-loan",
    token
  );

  const {
    data: customerData,
    isLoading: isLoadingCustomer,
    isError: isErrorCustomer,
  } = useFetchObjects(["customer"], "customers/", token);

  useEffect(() => {
    if (customerData) {
      setLoanData(customerData);
    }
  }, [customerData]);

  useEffect(() => {
    if (data) {
      setFormData({
        customer: data.customer,
        loan_amount: data.loan_amount,

        currency: data.currency || "AFG",
        loan_date: data.loan_date,
      });
      setLoanAmount(data.loan_amount || 0); // Initialize customer loan_amount
    }
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    // Prepare the data for the PUT request
    const updatedData = {
      customer: formData.customer.id, // Assuming customer is an object with an ID
      loan_amount: formData.loan_amount,

      currency: formData.currency,
      loan_date: formData.loan_date,
    };

    handleUpdate(loan.id, updatedData); // Call handleUpdate with the loan ID and data
  };

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    if (formErrors[field]) {
      setFormErrors({ ...formErrors, [field]: "" });
    }

    // Update the customer loan_amount based on the selected customer
    if (field === "customer" && value) {
      setLoanAmount(value.loan_amount); // Set the customer loan_amount
      setFormData({ ...formData, loan_amount: "", customer: value }); // Reset loan_amount field, maintain customer selection
    }
  };

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid item xs={11} md={6}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            maxWidth: "600px",
            width: "100%",
            margin: "auto",
            padding: "40px",
            boxShadow: 4,
            borderRadius: "12px",
            background: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <PersonAddIcon sx={{ fontSize: "50px", color: "primary.main" }} />
            <Typography
              variant="h4"
              component="h1"
              sx={{ marginLeft: "10px", fontWeight: "bold" }}
            >
              Edit Loan
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <FormMessageComponent />
          </Box>

          {/* Loan Autocomplete */}
          <Autocomplete
            options={loanData || []}
            getOptionLabel={(option) =>
              `${option?.firstname} ${option?.lastname}`
            }
            onChange={(event, value) => handleChange("customer", value)}
            value={
              customerData && loan
                ? customerData.find(
                    (customer) => customer.id === loan?.customer
                  ) || null
                : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customer"
                variant="standard"
                error={!!formErrors.customer}
                helperText={formErrors.customer}
                fullWidth
                sx={{ marginBottom: "20px" }}
              />
            )}
          />

          {/* Amount Field */}
          <TextField
            fullWidth
            type="number"
            variant="standard"
            label="Loan Amount"
            name="loan_amount"
            value={formData.loan_amount}
            error={!!formErrors.loan_amount}
            helperText={formErrors.loan_amount}
            onChange={(e) => handleChange("loan_amount", e.target.value)}
            inputProps={{
              max: loanAmount, // Set max value based on selected customer
            }}
            sx={{ mt: 3 }}
          />

          {/* Currency Field */}
          <TextField
            select
            fullWidth
            variant="standard"
            label="Currency"
            name="currency"
            value={formData.currency}
            onChange={(e) => handleChange("currency", e.target.value)}
            sx={{ mt: 3 }}
          >
            <MenuItem value="AFG">AFG</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="PKR">PKR</MenuItem>
          </TextField>

          {/* Loan Date Field */}
          <TextField
            fullWidth
            type="datetime-local"
            variant="standard"
            label="Loan Date"
            name="loan_date"
            value={formData.loan_date}
            error={!!formErrors.loan_date}
            helperText={formErrors.loan_date}
            onChange={(e) => handleChange("loan_date", e.target.value)}
            sx={{ mt: 3 }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Buttons onSubmit={handleSubmit} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditPastLoan;
