import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  CardActions,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Inventory2Icon from "@mui/icons-material/Inventory2"; // Using a product icon
import ErrorPage from "../../../../components/errorPage/ErrorPage";
import { useAuth } from "../../../../context/AuthContext";
import NoRecordMessage from "../../../../components/noRecordMessage/NoRecordMessage";
import TableSkeletonLoading from "../../../../components/tableSkeletonLoading/TableSkeletonLoading";
import { useNavigate } from "react-router-dom";
import useDelete from "../../../../api/useDelete";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";

const ProductList = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState();
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const [productName, setProductName] = useState("");
  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["ProductList"],
    `products/?search=${productName}`,
    token
  );

  const { handleDelete, DeleteMessageComponent, ConfirmDialog } = useDelete(
    `products`,
    token
  );

  useEffect(() => {
    refetch();
  }, [refetch, productName]);

  if (isLoading) {
    return <TableSkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid
        item
        xs={12}
        md={11.5}
        padding={"15px"}
        bgcolor={"white"}
        borderRadius={"5px"}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" fontWeight={"bold"} gutterBottom>
            {t("product.productList")}
          </Typography>
        </Box>

        <Grid
          container
          padding={"10px 0px"}
          borderBottom={"1px solid #d1c9b4"}
          bgcolor={"white"}
          position={"sticky"}
        >
          <Grid item xs={12} sm={10} md={4}>
            <TextField
              fullWidth
              type="search"
              size="small"
              placeholder={t("purchase.search")}
              name="productName"
              value={productName}
              onChange={(e) => {
                setProductName(e.target.value);
              }}
              sx={{
                bgcolor: "#e6e6eb",
                borderRadius: "4px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container mb={1}>
          <DeleteMessageComponent />
        </Grid>

        {data?.length === 0 ? (
          <NoRecordMessage entityName={t("product.product")} />
        ) : (
          <Grid
            container
            spacing={2}
            sx={{
              maxHeight: { xs: 655, md: 400 },
              overflowY: "auto",
              padding: "0px 10px",
              mt: 1,
            }}
          >
            {data.map((item, i) => (
              <Grid item xs={6} sm={3} md={2.4} key={i}>
                <Card
                  variant="outlined"
                  sx={{
                    borderRadius: "8px",
                    boxShadow: 2,
                    transition: "0.3s",
                    padding: "8px",
                    "&:hover": {
                      boxShadow: 4,
                    },
                  }}
                >
                  <CardContent sx={{ textAlign: "center", minHeight: 150 }}>
                    <Inventory2Icon
                      sx={{
                        fontSize: "40px",
                        color: "#3f51b5",
                        marginBottom: "8px",
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      {item.name}
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#333",
                      }}
                    >
                      {item.description?.slice(0, 24)}
                    </Typography>
                  </CardContent>
                  <CardActions
                    sx={{
                      justifyContent: "space-evenly",
                      paddingBottom: "8px",
                    }}
                  >
                    <Button
                      size="small"
                      sx={{ color: "#3f51b5" }}
                      onClick={() => {
                        navigate("/products/edit-product", {
                          state: { item },
                        });
                      }}
                    >
                      <EditIcon sx={{ marginRight: "4px" }} />
                      {t("product.edit")}
                    </Button>
                    <Button
                      size="small"
                      sx={{ color: "#f44336" }}
                      onClick={() => {
                        handleDelete(item.id);
                      }}
                    >
                      <DeleteIcon sx={{ marginRight: "4px" }} />
                      {t("product.delete")}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
        <ConfirmDialog />
      </Grid>
    </Grid>
  );
};

export default ProductList;
