import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import ReturnIcon from "@mui/icons-material/Undo";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"; // Icon for each product
import { Table } from "react-bootstrap"; // Import Bootstrap Table
import ErrorPage from "../../../../components/errorPage/ErrorPage";
import { useAuth } from "../../../../context/AuthContext";
import NoRecordMessage from "../../../../components/noRecordMessage/NoRecordMessage";
import TableSkeletonLoading from "../../../../components/tableSkeletonLoading/TableSkeletonLoading";

import useDelete from "../../../../api/useDelete";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useNavigate } from "react-router-dom";
import useUpdate from "../../../../api/useUpdate"; // Assuming there's a custom hook for update requests
import { t } from "i18next";

const SalesList = () => {
  const [returnFilter, setReturnFilter] = useState("");
  const [paidFilter, setPaidFilter] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSaleItems, setSelectedSaleItems] = useState([]);
  const [selectedSaleId, setSelectedSaleId] = useState(null);

  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["sales"],
    `sale-view/?is_returned=${returnFilter}&is_paid=${paidFilter}&search=${searchKeyword}`,
    token
  );

  const { handleDelete, DeleteMessageComponent, ConfirmDialog } = useDelete(
    `sales`,
    token
  );

  const { handleUpdate } = useUpdate("sale-item", token, "/sales"); // Custom hook for update request

  useEffect(() => {
    refetch();
  }, [returnFilter, paidFilter, searchKeyword]);

  const handleOpenDialog = (saleItems, saleId) => {
    setSelectedSaleItems(saleItems);
    setSelectedSaleId(saleId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedSaleItems([]);
  };

  const handleReturnItem = async (itemId) => {
    // Call the update API to mark the item as returned
    await handleUpdate(itemId, { is_returned: true });

    // Refetch the data after the update
    refetch();
    handleCloseDialog();
  };

  if (isLoading) {
    return <TableSkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid
        item
        xs={12}
        md={11.5}
        padding={"15px"}
        bgcolor={"white"}
        borderRadius={"5px"}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" fontWeight={"bold"} gutterBottom>
            {t("sales.salesList")}
          </Typography>
        </Box>

        <Grid container mb={1} justifyContent={"space-between"} spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              type="search"
              size="small"
              placeholder={t("purchase.search")}
              name="searchKeyword"
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              sx={{
                bgcolor: "#e6e6eb",
                borderRadius: "4px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} textAlign={"end"}>
            <FormControl size="small" sx={{ width: 150, mr: 1 }}>
              <InputLabel>{t("product.filterBy")}</InputLabel>
              <Select
                value={returnFilter}
                label={t("product.filterBy")}
                onChange={(e) => setReturnFilter(e.target.value)}
              >
                <MenuItem value={"all"}>{t("sales.all")}</MenuItem>
                <MenuItem value={"true"}>{t("sales.returned")}</MenuItem>
                <MenuItem value={"false"}>{t("sales.noReturned")}</MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ width: 150 }}>
              <InputLabel>{t("product.filterBy")}</InputLabel>
              <Select
                value={paidFilter}
                label={t("product.filterBy")}
                onChange={(e) => setPaidFilter(e.target.value)}
              >
                <MenuItem value={"all"}>{t("sales.all")}</MenuItem>
                <MenuItem value={"true"}>{t("sales.paid")}</MenuItem>
                <MenuItem value={"false"}>{t("sales.unpaid")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mb={1}>
          <DeleteMessageComponent />
        </Grid>

        {data?.length === 0 ? (
          <NoRecordMessage entityName={t("sales.sale")} />
        ) : (
          <div className="table-responsive">
            <Table bordered hover className="table table-striped table-sm">
              <thead className="table-dark">
                <tr>
                  <th>{t("purchase.num")}</th>
                  <th>{t("sales.customer")}</th>
                  <th>{t("sales.billnum")}</th>
                  <th>{t("sales.salesAmount")}</th>
                  <th>{t("sales.rAmount")}</th>
                  <th>{t("sales.lAmount")}</th>
                  <th>{t("sales.pStatus")}</th>
                  <th>{t("sales.salesDate")}</th>
                  <th>{t("sales.returned")}</th>
                  <th>{t("sales.details")}</th>
                  <th>{t("sales.action")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      {item.customer?.firstname} {item.customer?.lastname}
                    </td>
                    <td>{item.bill_no}</td>
                    <td>
                      {item.total_sale_amount} {item.currency === "AFG" && "؋"}{" "}
                      {item.currency === "USD" && "$"}{" "}
                      {item.currency === "PKR" && "R"}{" "}
                    </td>
                    <td>
                      {item.received_money || "N/A"}{" "}
                      {item.currency === "AFG" && "؋"}{" "}
                      {item.currency === "USD" && "$"}{" "}
                      {item.currency === "PKR" && "R"}{" "}
                    </td>
                    <td>
                      {item.total_sale_amount - item.received_money || "N/A"}{" "}
                      {item.currency === "AFG" && "؋"}{" "}
                      {item.currency === "USD" && "$"}{" "}
                      {item.currency === "PKR" && "R"}{" "}
                    </td>
                    <td>
                      {item.is_paid ? t("sales.paid") : t("sales.unpaid")}
                    </td>
                    <td>{new Date(item.sale_date).toLocaleDateString()}</td>
                    <td>
                      {item.is_returned ? t("sales.yes") : t("sales.no")}

                      <IconButton
                        size="small"
                        onClick={() =>
                          handleOpenDialog(item.sale_items, item.id)
                        }
                      >
                        <ReturnIcon />
                      </IconButton>
                    </td>
                    <td
                      onClick={() => {
                        navigate("/sales/sale-detail", { state: { item } });
                      }}
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {t("sales.viewDetails")}
                    </td>
                    <td
                      onClick={() => {
                        handleDelete(item.id);
                      }}
                      style={{
                        color: "red",
                        cursor: "pointer",
                      }}
                    >
                      <DeleteIcon />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        {/* Improved Dialog for returning items */}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" fontWeight="bold">
                {t("sales.rSaleItem")}
              </Typography>
              <IconButton onClick={handleCloseDialog}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <List>
              {selectedSaleItems.map((saleItem) => (
                <Box key={saleItem.id} mb={1}>
                  <ListItem alignItems="flex-start">
                    <Avatar sx={{ bgcolor: "#3f51b5", mr: 2 }}>
                      <ShoppingCartIcon />
                    </Avatar>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" fontWeight="bold">
                          {saleItem.display_product}
                        </Typography>
                      }
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleReturnItem(saleItem.id)}
                      sx={{ borderRadius: "20px", textTransform: "none" }}
                      disabled={saleItem.is_returned}
                    >
                      {t("sales.return")}
                    </Button>
                  </ListItem>
                  <Divider />
                </Box>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              variant="outlined"
              color="secondary"
              sx={{ borderRadius: "20px", textTransform: "none" }}
            >
              {t("sales.close")}
            </Button>
          </DialogActions>
        </Dialog>

        <ConfirmDialog />
      </Grid>
    </Grid>
  );
};

export default SalesList;
