import React from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Button } from "@mui/material";

const GenericPdfDownloader = ({
  children,
  downloadFileName,
  rootElementId,
}) => {
  const handleDownload = async () => {
    const input = document.getElementById(rootElementId);
    if (!input) return;

    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();

    const imgWidth = 190; // Width of the image in mm
    const pageHeight = pdf.internal.pageSize.height;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const heightLeft = imgHeight;

    let position = 0;

    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    position += heightLeft;

    // Add more pages if needed
    if (heightLeft >= pageHeight) {
      let heightLeft = imgHeight - pageHeight;
      while (heightLeft >= 0) {
        pdf.addPage();
        pdf.addImage(
          imgData,
          "PNG",
          10,
          position - heightLeft,
          imgWidth,
          imgHeight
        );
        heightLeft -= pageHeight;
      }
    }

    pdf.save(downloadFileName);
  };

  return (
    <div>
      <div id={rootElementId}>{children}</div>
      <Button
        variant="contained"
        color="success"
        sx={{ textTransform: "capitalize" }}
        onClick={handleDownload}
      >
        Download PDF
      </Button>
    </div>
  );
};

export default GenericPdfDownloader;
