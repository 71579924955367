import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState, useEffect } from "react";
import { Grid, Button, CircularProgress, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useTranslation } from "react-i18next";

const useAdd = (queryKey, token) => {
  const [data, setData] = useState([]);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [formMessageOpen, setFormMessageOpen] = useState(false);
  const [formMessageSeverity, setFormMessageSeverity] = useState("success");
  const [responseData, setResponseData] = useState("");

  const { t } = useTranslation();

  const addMutation = useMutation({
    mutationFn: async (data) => {
      setLoading(true);
      const headers = token ? { Authorization: `Token ${token}` } : {};
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/${queryKey}/`,
          data,
          { headers }
        );
        setResponseData(response.data);
        return response.data;
      } catch (error) {
        // Handle different backend error formats
        let backendErrorMessage = "";

        if (error.response?.data?.non_field_errors) {
          // Handle array of non_field_errors
          backendErrorMessage = error.response.data.non_field_errors.join(", ");
        } else if (error.response?.data?.error) {
          // Handle string error
          backendErrorMessage = error.response.data.error;
        } else {
          // Fallback to generic error message
          backendErrorMessage = t("useadd.addFailed");
        }

        throw new Error(backendErrorMessage);
      }
    },
    onSuccess: (response) => {
      setLoading(false);
      // Use backend message if available, otherwise fallback to static message
      const successMessage = response?.message || t("useadd.dataAddedSuccess");
      setFormMessage(successMessage);
      setFormMessageSeverity("success");
      setFormMessageOpen(true);
      setResetForm(true);
      queryClient.invalidateQueries(queryKey);
      setData(data);
    },
    onError: (error) => {
      setLoading(false);
      // Display the backend error or fallback to a static message
      const errorMessage = error.message || t("useadd.addFailed");
      setFormMessage(errorMessage);
      setFormMessageSeverity("error");
      setFormMessageOpen(true);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (formMessageOpen) {
      const timer = setTimeout(() => {
        setFormMessageOpen(false);
        setResetForm(false);
      }, 3000);

      return () => clearTimeout(timer); // Cleanup on component unmount
    }
  }, [formMessageOpen]);

  const handleAdd = (data) => {
    addMutation.mutate(data);
  };

  const FormMessageComponent = () => (
    <Box
      sx={{
        display: formMessageOpen ? "flex" : "none",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: formMessageSeverity === "success" ? "green" : "red",
        color: "#fff",
        padding: "10px 15px",
        borderRadius: "4px",
        mt: 2,
        position: "relative",
      }}
    >
      <Box>{formMessage}</Box>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setFormMessageOpen(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );

  const Buttons = ({ onSubmit }) => (
    <Grid container justifyContent={"end"} mt={2}>
      <Button
        variant="contained"
        color="primary"
        disabled={loading}
        sx={{ mr: 2 }}
        onClick={() => setResetForm(true)} // Optional: Reset form manually if needed
      >
        {t("useadd.cancel")}
      </Button>
      <Button
        variant="contained"
        onClick={onSubmit}
        color="primary"
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} />}
      >
        {loading ? t("useadd.adding") : t("useadd.submit")}
      </Button>
    </Grid>
  );

  return {
    handleAdd,
    Buttons,
    resetForm,
    setResetForm,
    FormMessageComponent,
    responseData,
    loading,
  };
};

export default useAdd;
