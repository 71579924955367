import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { useTranslation } from "react-i18next";
import Invoice from "./Invoice"; // Import your Invoice component

const SaleDetails = () => {
  const location = useLocation();
  const sale = location?.state?.item;
  const { t } = useTranslation(); // Localization hook
  const printRef = useRef(); // Reference for the content to print

  const handlePrint = () => {
    const printContent = printRef.current.innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
    window.location.reload(); // Restore the page after printing
  };

  if (!sale) {
    return (
      <Box mt={5} textAlign="center">
        <Typography variant="h6" color="textSecondary">
          {t("sales.noAvailable")}
        </Typography>
      </Box>
    );
  }

  const {
    customer,
    sale_date,
    received_money,
    total_sale_amount,
    is_paid,
    sales_type,
    sale_items,
    currency,
  } = sale;

  const totalAmount = parseFloat(total_sale_amount) || 0;
  const receivedAmount = parseFloat(received_money) || 0;
  const remainedAmount = totalAmount - receivedAmount;

  return (
    <Grid container justifyContent={"center"} margin={"20px 0px"}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Typography variant="h4" color="primary">
            {t("sales.salesDetails")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePrint}
            sx={{
              "@media print": {
                display: "none", // Hide button when printing
              },
            }}
          >
            {t("sales.print")} <PrintIcon />
          </Button>
        </Box>

        {/* Display sale information */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" color="primary" gutterBottom>
                  {t("sales.cInformation")}
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>{t("sales.name")}</strong>
                        </TableCell>
                        <TableCell>{`${customer?.firstname} ${customer?.lastname}`}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>{t("sales.phone")}</strong>
                        </TableCell>
                        <TableCell>{customer?.phone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>{t("sales.address")}</strong>
                        </TableCell>
                        <TableCell>{customer?.address}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={7}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" color="primary" gutterBottom>
                  {t("sales.sInformation")}
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>{t("sales.salesDate")}</strong>
                        </TableCell>
                        <TableCell>
                          {new Date(sale_date).toLocaleString()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>{t("sales.salesType")}</strong>
                        </TableCell>
                        <TableCell>{sales_type}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>{t("sales.totalSAmount")}</strong>
                        </TableCell>
                        <TableCell>
                          {totalAmount.toFixed(2)} {currency === "AFG" && "؋"}{" "}
                          {currency === "USD" && "$"}{" "}
                          {currency === "PKR" && "R"}{" "}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>{t("sales.rMoney")}</strong>
                        </TableCell>
                        <TableCell>
                          {receivedAmount.toFixed(2)}{" "}
                          {currency === "AFG" && "؋"}{" "}
                          {currency === "USD" && "$"}{" "}
                          {currency === "PKR" && "R"}{" "}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>{t("sales.reAmount")}</strong>
                        </TableCell>
                        <TableCell>
                          {remainedAmount.toFixed(2)}{" "}
                          {currency === "AFG" && "؋"}{" "}
                          {currency === "USD" && "$"}{" "}
                          {currency === "PKR" && "R"}{" "}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>{t("sales.paids")}</strong>
                        </TableCell>
                        <TableCell>
                          {is_paid ? t("sales.yes") : t("sales.no")}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <strong> {t("sales.currency")}</strong>
                        </TableCell>
                        <TableCell>{currency}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box mt={4}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                {t("sales.saleItem")}
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("sales.product")}</TableCell>
                      <TableCell>{t("sales.quantity")}</TableCell>
                      <TableCell>{t("sales.perPrice")}</TableCell>
                      <TableCell>{t("sales.total")}</TableCell>
                      <TableCell>{t("sales.returned")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sale_items.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.display_product}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>
                          {parseFloat(item.per_price).toFixed(2)}{" "}
                          {item?.currency === "AFG" && "؋"}{" "}
                          {item?.currency === "USD" && "$"}{" "}
                          {item?.currency === "PKR" && "R"}{" "}
                        </TableCell>
                        <TableCell>
                          {(item.quantity * parseFloat(item.per_price)).toFixed(
                            2
                          )}{" "}
                          {item?.currency === "AFG" && "؋"}{" "}
                          {item?.currency === "USD" && "$"}{" "}
                          {item?.currency === "PKR" && "R"}{" "}
                        </TableCell>

                        <TableCell>
                          {item.is_returned ? t("sales.yes") : t("sales.no")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>

        {/* Print Section */}
        <div style={{ display: "none" }}>
          <div ref={printRef}>
            <Invoice sale={sale} />
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

export default SaleDetails;
