import React from "react";
import { Route, Routes } from "react-router-dom";
import LoanIndex from "../../pages/dashboard/past_loan/PastLoanIndex";
import AddPastLoan from "../../pages/dashboard/past_loan/addPastLoan/AddPastLoan";
import EdiPastLoan from "../../pages/dashboard/past_loan/editPastLoan/EdiPastLoan";
import PastLoanList from "../../pages/dashboard/past_loan/pastLoanList/PastLoanList"
import PastLoanReports from "../../pages/dashboard/past_loan/pastLoanReports/PastLoanReports";

const PastLoanRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LoanIndex />}>
        <Route path="/" element={<PastLoanList />} />
        <Route path="/add-past-loan" element={<AddPastLoan />} />
        <Route path="/edit-past-loan" element={<EdiPastLoan />} />
        <Route path="/past-loan-reports" element={<PastLoanReports />} />
      </Route>
    </Routes>
  );
};

export default PastLoanRoutes;
