import { Grid } from "@mui/material";
import React from "react";

import { Outlet } from "react-router-dom";
import ExpenseNavBar from "./expenseNavBar/ExpenseNavBar";

const ExpenseIndex = () => {
  return (
    <Grid container>
      <ExpenseNavBar />
      <Outlet />
    </Grid>
  );
};

export default ExpenseIndex;
