import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  TableContainer,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { CurrencyExchange as CurrencyIcon } from "@mui/icons-material";
import { Button } from "react-bootstrap";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context/AuthContext";
import ErrorPage from "../../../../components/errorPage/ErrorPage";
import TableSkeletonLoading from "../../../../components/tableSkeletonLoading/TableSkeletonLoading";
import "./style.css"; // Importing the CSS file
import { useTranslation } from "react-i18next";

const iconStyle = { fontSize: 50, mb: 1, color: "#2e7d32" };

// SalesReports Component
const SalesReports = () => {
  const {t}=useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch data from the backend
  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["Reports"],
    `report/sales/?start_date=${startDate}&end_date=${endDate}&search=${searchTerm}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [refetch, searchTerm]);

  // Handle filtering
  const handleFilter = () => {
    refetch();
  };

  if (isLoading) {
    return <TableSkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!data) {
    return <Typography>{t("report.noSale")}</Typography>;
  }

  // Ensure sales_by_currency has a default value if it's undefined or null
  const { sales_by_currency = {}, product_sales = [], customer_sales = [] } = data;

  return (
    <Grid container justifyContent={"center"} margin={"20px 0px"}>
      <Grid
        item
        xs={11}
        bgcolor={"white"}
        padding={"10px 20px"}
        borderRadius={"10px"}
      >
        <Grid container>
          <Typography variant="h4" gutterBottom>
          {t("report.saleReport")}
          </Typography>

          {/* Date Filters */}
          <Grid container spacing={2} marginBottom={4} mt={1}>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                type="date"
                label={t("report.startDate")}
                variant="outlined"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                type="date"
                label={t("report.endDate")}
                variant="outlined"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                fullWidth
                placeholder={t("report.searchByCP")}
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Button
                  variant="primary"
                  onClick={handleFilter}
                  className="w-100"
                >
                 {t("report.applyFilter")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Total Sales by Currency */}
        <Typography variant="h5" gutterBottom>
          
          {t("report.saleByCurrency")}
        </Typography>
        <Grid container spacing={2} marginBottom={4}>
          {Object.entries(sales_by_currency).map(([currency, totals]) => (
            <Grid key={currency} item xs={12} md={4}>
              <Card className="text-center bg-light p-3">
                <CardContent>
                  <CurrencyIcon className="rotating-icon" sx={iconStyle} />
                  <Typography
                    variant="h5"
                    fontWeight={"bold"}
                    color="textSecondary"
                  >
                    {currency} - {t("report.rSale")}
                  </Typography>
                  <Typography variant="h4" fontWeight={"bold"} color="primary">
                    {totals?.retail_sales_total?.toLocaleString()} {currency}
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight={"bold"}
                    color="textSecondary"
                    marginTop={2}
                  >
                    {currency} - {t("report.wSale")}
                  </Typography>
                  <Typography variant="h4" fontWeight={"bold"} color="primary">
                    {totals?.wholesale_sales_total?.toLocaleString()} {currency}
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight={"bold"}
                    color="textSecondary"
                    marginTop={2}
                  >
                    {currency} - {t("report.oveTotalSale")}
                  </Typography>
                  <Typography variant="h4" fontWeight={"bold"} color="primary">
                    {totals?.overall_total_sales?.toLocaleString()} {currency}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Product Sales Table */}
        <Typography variant="h5" gutterBottom>
         
          {t("report.productSale")}
        </Typography>
        <TableContainer elevation={3} className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-dark">
              <tr>
                <th> {t("report.productName")}</th>
                <th> {t("report.saleType")}</th>
                <th> {t("report.quanSold")}</th>
                <th> {t("report.unitPrice")}</th>
                <th> {t("report.totalPrice")}</th>
              </tr>
            </thead>
            <tbody>
              {product_sales?.map((sale, index) => (
                <tr key={index}>
                  <td>{sale?.product__name}</td>
                  <td>{sale?.sale__sales_type}</td>
                  <td>{sale?.total_quantity}</td>
                  <td>؋ {sale?.unit_price?.toLocaleString()}</td>
                  <td>؋ {sale?.total_price?.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>

        {/* Customer Sales Table */}
        <Typography variant="h5" gutterBottom className="mt-4">
        {t("report.customer")}
        </Typography>
        <TableContainer elevation={3} className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-dark">
              <tr>
                <th>{t("report.customerFname")}</th>
                <th>{t("report.customerLname")}</th>
                <th>{t("report.totalBought")}</th>
              </tr>
            </thead>
            <tbody>
              {customer_sales?.map((customer, index) => (
                <tr key={index}>
                  <td>{customer?.sale__customer__firstname || "N/A"}</td>
                  <td>{customer?.sale__customer__lastname || "N/A"}</td>
                  <td>{customer?.total_bought}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default SalesReports;
