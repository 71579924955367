import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "react-bootstrap"; // Import Bootstrap Table
import ErrorPage from "../../../../components/errorPage/ErrorPage";
import { useAuth } from "../../../../context/AuthContext";
import NoRecordMessage from "../../../../components/noRecordMessage/NoRecordMessage";
import TableSkeletonLoading from "../../../../components/tableSkeletonLoading/TableSkeletonLoading";

import useDelete from "../../../../api/useDelete";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SupplierList = () => {
  const {t}=useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const [keyword, setKeyword] = useState("");

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["supplierList"],
    `suppliers/?search=${keyword}`,
    token
  );

  const { handleDelete, DeleteMessageComponent, ConfirmDialog } = useDelete(
    `suppliers`,
    token
  );

  useEffect(() => {
    refetch();
  }, [keyword, refetch]);

  if (isLoading) {
    return <TableSkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid
        item
        xs={12}
        md={11.5}
        padding={"15px"}
        bgcolor={"white"}
        borderRadius={"5px"}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" fontWeight={"bold"} gutterBottom>
            
            {t("supplier.supplierList")}
          </Typography>
        </Box>

        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item xs={12} md={5} mb={1}>
            <TextField
              fullWidth
              type="search"
              size="small"
              placeholder={t("purchase.search")}
              name="keyword"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              sx={{
                bgcolor: "#e6e6eb",
                borderRadius: "4px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container mb={1}>
          <DeleteMessageComponent />
        </Grid>

        {data?.length === 0 ? (
          <NoRecordMessage entityName={t("supplier.supplier")}/>
        ) : (
          <div className="table-responsive">
            <Table bordered hover className="table table-striped table-sm">
              <thead class="table-dark">
                <tr>
                  <th>{t("purchase.num")}</th>
                  <th>{t("customer.fName")}</th>
                  <th>{t("customer.lName")}</th>
                  <th>{t("customer.faName")}</th>
                  <th>{t("customer.email")}</th>
                  <th>{t("customer.phone")}</th>
                  <th>{t("customer.address")}</th>
                  <th>{t("supplier.paymentTerm")}</th>
                  <th>{t("purchase.action")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, i) => (
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>{item.firstname}</td>
                    <td>{item.lastname}</td>
                    <td>{item.father_name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{item.address}</td>
                    <td>{item.payment_terms}</td>

                    <td>
                      <EditIcon
                        sx={{ color: "blue", cursor: "pointer", mr: 1 }}
                        onClick={() => {
                          navigate("/suppliers/edit-supplier", {
                            state: { item },
                          });
                        }}
                      />

                      <DeleteIcon
                        sx={{ color: "#f09690", cursor: "pointer" }}
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <ConfirmDialog />
      </Grid>
    </Grid>
  );
};

export default SupplierList;
