import React, { useEffect, useState } from "react";
import { Box, createTheme, Grid, ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";
import NavBar from "./layouts/navBar/NavBar";

function App() {
  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    const updateDirection = () => {
      const savedDirection = localStorage.getItem("direction");
      setDirection(savedDirection || "ltr");
    };

    updateDirection();
  }, []);

  const theme = createTheme({
    direction: direction,
    typography: {
      fontFamily:
        direction === "rtl"
          ? "'Noto Naskh Arabic', sans-serif"
          : "'Noto Sans', sans-serif",
    },
  });

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <NavBar />
        <Grid container maxWidth="xl" margin={"0px auto"}>
          <Outlet />
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default App;
