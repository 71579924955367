import React, { useRef } from "react";
import { Box, Typography, Grid, Paper, Button } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";

const Invoice = ({ sale }) => {
  const { t } = useTranslation(); // Access translation hook
  const printRef = useRef(); // Reference for the print content

  const {
    customer,
    sale_date,
    received_money,
    total_sale_amount,
    sale_items,
    bill_no,
    currency,
  } = sale;

  const totalAmount = parseFloat(total_sale_amount) || 0;
  const receivedAmount = parseFloat(received_money) || 0;
  const remainedAmount = totalAmount - receivedAmount;

  // Handle print functionality
  const handlePrint = () => {
    window.print(); // Simply trigger the print action
  };

  return (
    <div>
      {/* Button to trigger printing */}
      <Button
        variant="contained"
        color="primary"
        onClick={handlePrint}
        sx={{
          marginBottom: "20px",
          "@media print": {
            display: "none", // Hide button during print
          },
        }}
      >
        {t("invoice.print_invoice")}
      </Button>

      {/* Print Content */}
      <Paper
        ref={printRef} // Reference for print content
        sx={{
          padding: "20px",
          width: "210mm", // A4 width
          minHeight: "297mm", // A4 height
          boxShadow: "none",
        }}
      >
        {/* Header Section */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box
            component={"img"}
            src={`${process.env.REACT_APP_MEDIA_URL}images/logo.png`}
            sx={{ width: "15vw", height: "10vh" }}
          />
          <Box textAlign="right">
            <Typography variant="h4" color={"blue"}>
              <strong>{t("invoice.title")}</strong>
            </Typography>
            <Typography variant="body2">
              <strong>{t("invoice.bill_no")}: </strong> {bill_no}
            </Typography>
            <Typography variant="body2">
              <strong>{t("invoice.date")}: </strong>{" "}
              {new Date(sale_date).toLocaleDateString()}
            </Typography>
            <Typography variant="body2">
              <strong>{t("invoice.customer")}: </strong> {customer?.firstname}{" "}
              {customer?.lastname}
            </Typography>
          </Box>
        </Box>

        {/* Body Section */}
        <Grid
          container
          spacing={3}
          justifyContent={"space-between"}
          margin={"20px 0px"}
        >
          <Grid item xs={5.5}>
            <Typography>
              We Provide Better Services With Best Quality of Paints
            </Typography>
            <Typography fontWeight={"bold"} textAlign={"center"}>
              BERAJ
            </Typography>
            <Typography>
              With New Brand of Germany Challenger Paints For Vehicles Different
              Color, Shine Your Dream Cars With Challenger Paints
            </Typography>

            <Typography fontWeight={"bold"} textAlign={"center"}>
              CHALLENGER
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography textAlign={"start"}>
              وارد کننده رنگهای تعمیراتی و عراده جات بهترین برندهای خارجی بیراج
              کورآپ و چلنجر برای آپارتمانها و ها وخاههای زیبای شما و برای هرنوع
              موتر های شما موجود میباشد
            </Typography>
          </Grid>
        </Grid>

        {/* Bootstrap Table for Customer Details */}
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead style={{ backgroundColor: "#243863", color: "#fff" }}>
              <tr>
                <th>{t("invoice.customer_name")}</th>
                <th>{t("invoice.customer_phone")}</th>
                <th>{t("invoice.customer_address")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {customer?.firstname} {customer?.lastname}
                </td>
                <td>{customer?.phone}</td>
                <td>{customer?.address}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Bootstrap Table for Sale Items */}
        <div className="table-responsive">
          <table className="table table-bordered mt-2">
            <thead style={{ backgroundColor: "#243863", color: "#fff" }}>
              <tr>
                <th>{t("invoice.product")}</th>
                <th>{t("invoice.quantity")}</th>
                <th>{t("invoice.unit_price")}</th>
                <th>{t("invoice.currency")}</th>
                <th>{t("invoice.total")}</th>
              </tr>
            </thead>
            <tbody>
              {sale_items.map((item, index) => (
                <tr key={index}>
                  <td>{item.display_product}</td>
                  <td>{item.quantity}</td>
                  <td>
                    {parseFloat(item.per_price).toFixed(2)}{" "}
                    {currency === "AFG" && "؋"} {currency === "USD" && "$"}{" "}
                    {currency === "PKR" && "R"}
                  </td>
                  <td>
                    {currency === "AFG" && "افغانی"}{" "}
                    {currency === "USD" && "دالر"}{" "}
                    {currency === "PKR" && "کالدار"}
                  </td>
                  <td>
                    {currency === "AFG" && "؋"}
                    {(item.quantity * parseFloat(item.per_price)).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Totals Section */}
        <Box mt={4}>
          <Grid container justifyContent="space-between">
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                {t("invoice.total_amount")}:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {currency === "AFG" && "؋"} {totalAmount.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" mt={2}>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                {t("invoice.received")}:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {currency === "AFG" && "؋"} {receivedAmount.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" mt={2}>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                {t("invoice.remaining_amount")}:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {currency === "AFG" && "؋"} {remainedAmount.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Footer Section */}
        <Box mt={4} p={2} sx={{ borderTop: "1px solid black" }}>
          <Typography variant="body2">{t("invoice.footer_address")}</Typography>
          <Typography variant="body2">{t("invoice.footer_phone")}</Typography>
          <Typography variant="body2">{t("invoice.footer_email")}</Typography>
        </Box>

        <Box mt={4} p={2} sx={{ direction: "ltr" }}>
          <Typography variant="body2">
            @{t("invoice.footer_powered_by")}
          </Typography>
        </Box>
      </Paper>

      {/* Add print-specific CSS */}
      <style jsx>{`
        @media print {
          #printRef,
          #printRef * {
            visibility: visible; /* Only show the print content */
          }
          #printRef {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
          }
          /* Avoid page breaks in tables */
          table {
            page-break-inside: avoid;
          }
          /* Reduce margins and fit all content into one page */
          @page {
            size: A4;
            margin: 0;
          }
          html,
          body {
            width: 210mm;
            height: 297mm;
          }
        }
      `}</style>
    </div>
  );
};

export default Invoice;
