import { Grid } from "@mui/material";
import React from "react";

import { Outlet } from "react-router-dom";
import LoanNavBar from "./loanNavBar/LoanNavBar";

const LoanIndex = () => {
  return (
    <Grid container>
      <LoanNavBar />
      <Outlet />
    </Grid>
  );
};

export default LoanIndex;
