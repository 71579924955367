import React from "react";
import { Route, Routes } from "react-router-dom";
import PurchaseIndex from "../../pages/dashboard/purchase/PurchaseIndex";
import PurchaseList from "../../pages/dashboard/purchase/purchaseList/PurchaseList";
import AddPurchase from "../../pages/dashboard/purchase/addPurchase/AddPurchase";
import EditPurchase from "../../pages/dashboard/purchase/editPurchase/EditPurchase";

const PurchaseRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PurchaseIndex />}>
        <Route path="/" element={<PurchaseList />} />
        <Route path="/add-purchase" element={<AddPurchase />} />
        <Route path="/edit-purchase" element={<EditPurchase />} />
      </Route>
    </Routes>
  );
};

export default PurchaseRoutes;
