import React from "react";
import { Route, Routes } from "react-router-dom";
import LoanIndex from "../../pages/dashboard/loan/LoanIndex";
import LoanList from "../../pages/dashboard/loan/laonList/LoanList";
import AddLoan from "../../pages/dashboard/loan/addLoan/AddLoan";
import EditLoan from "../../pages/dashboard/loan/editLoan/EditLoan";

const LoanRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LoanIndex />}>
        <Route path="/" element={<LoanList />} />
        <Route path="/add-loan" element={<AddLoan />} />
        <Route path="/edit-loan" element={<EditLoan />} />
      </Route>
    </Routes>
  );
};

export default LoanRoutes;
