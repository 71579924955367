import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "react-bootstrap"; // Import Bootstrap Table
import ErrorPage from "../../../../components/errorPage/ErrorPage";
import { useAuth } from "../../../../context/AuthContext";
import NoRecordMessage from "../../../../components/noRecordMessage/NoRecordMessage";
import TableSkeletonLoading from "../../../../components/tableSkeletonLoading/TableSkeletonLoading";

import useDelete from "../../../../api/useDelete";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PurchasesList = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["purchases"],
    `purchases/?search=${searchTerm}`,
    token
  );

  const { handleDelete, DeleteMessageComponent, ConfirmDialog } = useDelete(
    `purchases`,
    token
  );

  const [openDialog, setOpenDialog] = useState(false); // State to control dialog
  const [selectedPurchase, setSelectedPurchase] = useState(null); // State to hold selected purchase details

  useEffect(() => {
    refetch();
  }, [searchTerm, refetch]);

  const handleRowClick = (item) => {
    setSelectedPurchase(item);
    setOpenDialog(true); // Open the dialog when a row is clicked
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPurchase(null); // Reset selected purchase when dialog is closed
  };

  if (isLoading) {
    return <TableSkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Grid container justifyContent={"center"} margin={"10px 0px"}>
      <Grid
        item
        xs={12}
        md={11.9}
        padding={"15px"}
        bgcolor={"white"}
        borderRadius={"5px"}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" fontWeight={"bold"} gutterBottom>
            {t("purchase.purchaseList")}
          </Typography>
        </Box>

        <Grid item xs={12} md={4} mb={1}>
          <TextField
            fullWidth
            type="search"
            size="small"
            placeholder={t("purchase.search")}
            sx={{
              bgcolor: "#e6e6eb",
              borderRadius: "4px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Grid>
        <Grid container mb={1}>
          <DeleteMessageComponent />
        </Grid>

        {data?.length === 0 ? (
          <NoRecordMessage entityName={t("purchase.purchase")} />
        ) : (
          <div className="table-responsive">
            <Table bordered hover className="table table-striped table-sm">
              <thead className="table-dark">
                <tr style={{ fontSize: "11px" }}>
                  <th>{t("purchase.num")}</th>
                  <th>{t("purchase.product")}</th>
                  <th>{t("purchase.supplier")}</th>
                  <th>{t("purchase.quantity")}</th>
                  <th>{t("purchase.unitPrice")}</th>
                  <th>
                    {t("purchase.wholesalePrice")}
                    {t("inventory.afg")}
                  </th>
                  <th>
                    {t("purchase.wholesalePrice")}
                    {t("inventory.usd")}
                  </th>
                  <th>
                    {t("purchase.wholesalePrice")}
                    {t("inventory.pkr")}
                  </th>
                  <th>
                    {t("purchase.retailSalePrice")}
                    {t("inventory.afg")}
                  </th>
                  <th>
                    {t("purchase.retailSalePrice")}
                    {t("inventory.usd")}
                  </th>
                  <th>
                    {t("purchase.retailSalePrice")}
                    {t("inventory.pkr")}
                  </th>
                  <th>{t("purchase.totalAmount")}</th>
                  <th>{t("purchase.action")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, i) => (
                  <tr
                    key={i}
                    onClick={() => handleRowClick(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{item.id}</td>
                    <td>{item.display_product}</td>
                    <td>{item.display_supplier}</td>
                    <td>{item.purchase_quantity}</td>
                    <td>{item.unit_price}</td>

                    <td>{item.whole_sale_price_in_afg}</td>
                    <td>{item.whole_sale_price_in_usd}</td>
                    <td>{item.whole_sale_price_in_pkr}</td>
                    <td>{item.retail_sale_price_in_afg}</td>
                    <td>{item.retail_sale_price_in_usd}</td>
                    <td>{item.retail_sale_price_in_pkr}</td>
                    <td>{item.total_amount}</td>
                    <td>
                      <EditIcon
                        sx={{ color: "blue", cursor: "pointer", mr: 1 }}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click event
                          navigate("/purchases/edit-purchase", {
                            state: { item },
                          });
                        }}
                      />

                      <DeleteIcon
                        sx={{ color: "#f09690", cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click event
                          handleDelete(item.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <ConfirmDialog />

        {/* Dialog for showing purchase details */}
        {selectedPurchase && (
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Description</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {selectedPurchase.description}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Close</Button>
            </DialogActions>
          </Dialog>
        )}
      </Grid>
    </Grid>
  );
};

export default PurchasesList;
